import { API, STAFF_TEAM, ATTENDANCE_DASHBOARD } from '@/utils/constant';
import axios from 'axios';
import moment from 'moment';
const { cloneDeep } = require('lodash');

const attendance = {
  namespaced: true,
  state: {
    targetGroup: 'All',
    timeFrom: moment()
      .startOf('isoWeek')
      .format('YYYYMMDD'),
    timeTo: moment()
      .endOf('isoWeek')
      .format('YYYYMMDD'),
    timeStride: 'week',
    queryData: {},
    dashboardData: [],
    isLoading: false,
    shouldWorkDays: 0,
    totalMembers: 0,
    supplementRate: 0,
    abnormalRate: 0,
    invalidSwipe: 0,
    teamAvgShouldWorkDays: 0,
    teamAvgWorkHour: 0,
    teamAvgOnStart: 0,
    teamAvgOnEnd: 0,
    supplementRateAll: 0,
    abnormalRateAll: 0,
    invalidSwipeAll: 0,
    totalMembersAll: 0,
    teamAvgWorkHourAll: 0,
    teamAvgOnStartAll: 0,
    teamAvgOnEndAll: 0,
    teamAvgShouldWorkDaysAll: 0,
    showPersonalDetail: 0,
    viewDepth: 0,
    detailData: [],
    targetPerson: null,
    breadcrumbs: [],
    filterLoc: 'All',
    gotoHome: false,
    historyList: [],
    isSingleLevel: false,
  },
  mutations: {
    initAttandance(state) {
      state.targetGroup = 'All';
      state.timeFrom = moment()
        .startOf('isoWeek')
        .format('YYYYMMDD');
      state.timeTo = moment()
        .endOf('isoWeek')
        .format('YYYYMMDD');
      state.timeStride = 'week';
      state.queryData = {};
      state.dashboardData = [];
      state.isLoading = false;
      state.shouldWorkDays = 0;
      state.totalMembers = 0;
      state.totalMembersAll = 0;
      state.supplementRate = 0;
      state.abnormalRate = 0;
      state.invalidSwipe = 0;
      state.supplementRateAll = 0;
      state.abnormalRateAll = 0;
      state.invalidSwipeAll = 0;
      state.teamAvgShouldWorkDays = 0;
      state.teamAvgShouldWorkDaysAll = 0;
      state.teamAvgWorkHour = 0;
      state.teamAvgWorkHourAll = 0;
      state.teamAvgOnStart = 0;
      state.teamAvgOnStartAll = 0;
      state.teamAvgOnEnd = 0;
      state.teamAvgOnEndAll = 0;
      state.showPersonalDetail = 0;
      state.viewDepth = 0;
      state.detailData = [];
      state.targetPerson = null;
      state.filterLoc = 'All';
      state.gotoHome = false;
      state.historyList = [];
      state.isSingleLevel = false;
    },
    setGotoHome(state, go) {
      state.gotoHome = go;
    },
    setHistoryList(state, history) {
      state.historyList = history;
    },
    setFilterLoc(state, loc) {
      state.filterLoc = loc;
    },
    setBreadCrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    setTargetGroup(state, targetGroup) {
      state.targetGroup = targetGroup;
      if (state.targetGroup === 'Project') {
        state.dashboardData = state.queryData['byProject'];
      } else if (state.targetGroup === 'Function') {
        state.dashboardData = state.queryData['byFunction'];
      } else {
        state.dashboardData = state.queryData['byAll'];
      }

      if (state.targetGroup !== 'Function') {
        state.isSingleLevel = false;
      }
    },
    setTargetPerson(state, targetPerson) {
      state.targetPerson = targetPerson;
    },
    setDetailData(state, data) {
      state.detailData = data;
    },
    setViewDepth(state, depth) {
      state.viewDepth = depth;
    },
    setTimeFrom(state, from) {
      state.timeFrom = from;
    },
    setTimeTo(state, to) {
      state.timeTo = to;
    },
    setTimeStride(state, stride) {
      state.timeStride = stride;
    },
    setLoading(state, val) {
      state.isLoading = val;
    },
    setShouldWorkDays(state, val) {
      state.shouldWorkDays = val;
    },
    setSingleLevel(state, val) {
      state.isSingleLevel = val;
    },
    updateUI(state, resObj) {
      if (!resObj) {
        return;
      }
      if (resObj.group !== 'All') {
        if (resObj.team === undefined) {
          console.log(`updateUI only for team/subteam`);
          // for click All/Function
          moveTopDashBoardData(state);
          return;
        }
        // find the depthest team first
        let teamName;
        if (resObj.subsubSubTeam) {
          teamName = resObj.subsubSubTeam;
        } else if (resObj.subSubTeam) {
          teamName = resObj.subSubTeam;
        } else if (resObj.subTeam) {
          teamName = resObj.subTeam;
        } else {
          teamName = resObj.team;
        }
        const functionTeams =
          resObj.group === 'Function' ? state.queryData.Function : state.queryData.ProjectAll;

        const teamObj = functionTeams.find(
          entry => entry.val.alias === resObj.team || entry.val.name === resObj.team
        );
        let node = searchBF(teamObj, teamName);
        if (node === null) {
          console.log(`can't find ${teamName}`);
          return;
        }
        if (state.isSingleLevel) {
          node = validateSubordination(node);
        }
        const memberList = [];
        node.children.forEach(entry => memberList.push(entry));
        // update table only
        const memberAttendance = [];
        let index = -1;
        for (const member of memberList) {
          index = index + 1;
          for (const item of state.queryData['filterAll']) {
            if (member.val.name === item.name) {
              let isHighlight = false;
              if (member.children.length >= 1 && resObj.group === 'Function') {
                isHighlight = true;
              }
              const displayName = item.name;
              const groupNumber = member.headcount > 0 ? member.headcount : 1;
              let avgShouldWorkDay = member.data.shouldWorkDay / groupNumber;
              let avgAbnormalDay = member.data.abnormal / groupNumber;

              let memberNode = buildFunctionLeader(member);
              if (!memberNode) {
                memberNode = item;
                avgShouldWorkDay = parseFloat(item.shouldWorkDay);
                avgAbnormalDay = parseFloat(item.abnormalCount);
              }
              // prepare data for dashboard
              memberAttendance.push({
                ...memberNode,
                totoalMember: member.children.length,
                shouldWorkDay: avgShouldWorkDay !== 0 ? avgShouldWorkDay.toFixed(1) : 0,
                abnormalCount: avgAbnormalDay !== 0 ? avgAbnormalDay.toFixed(1) : 0,
                name: displayName,
                childNum: member.children.length,
                circle: isHighlight,
                subTree: member,
              });
            }
          }
        }
        // update dashboard
        const shouldWorkDay = node.data.shouldWorkDay === 0 ? 1 : node.data.shouldWorkDay;
        state.abnormalRate = Number((100 * node.data.abnormal) / shouldWorkDay).toFixed(1);
        state.invalidSwipe = Number((100 * node.data.onIllegalSwipe) / shouldWorkDay).toFixed(0);
        state.supplementRate = Number((100 * node.data.onSupplement) / shouldWorkDay).toFixed(0);
        state.totalMembers = node.headcount;
        state.teamAvgWorkHour = node.data.workHour === 0 ? 0 : node.data.workHour.toFixed(1);
        state.teamAvgOnStart =
          node.data.onStart !== null
            ? moment.utc(node.data.onStart * 3600 * 1000).format('HH:mm')
            : 0;
        state.teamAvgOnEnd =
          node.data.onEnd !== null ? moment.utc(node.data.onEnd * 3600 * 1000).format('HH:mm') : 0;
        const avgShouldWorkDaysTmp =
          node.data.shouldWorkDay / (node.headcount === 0 ? 1 : node.headcount);
        state.teamAvgShouldWorkDays =
          avgShouldWorkDaysTmp === 0 ? 0 : avgShouldWorkDaysTmp.toFixed(1);

        state.dashboardData = memberAttendance;
      } else {
        // for all
        moveTopDashBoardData(state);
      }

      // update group
      state.targetGroup = resObj.group;
    },
    addCrumb(state, alias) {
      const tailLinkId = state.breadcrumbs.filter(entry => entry.active === true)[0].to.params.id;
      console.log(`tail breadcrumbs link id - ${tailLinkId}`);
      const crumb = {
        text: alias,
        to: {
          name: ATTENDANCE_DASHBOARD,
          params: { id: `${tailLinkId}__${alias}` },
        },
        active: true,
      };
      updateCrumb(state.breadcrumbs, crumb, true);
    },
    setQueryData(state, data) {
      const projects = [];
      const allMembers = [];
      const functionTeams = [];
      let allFilterMembers = [];

      // for further usage backup
      buildTopLevelData('byAll', allMembers, data);
      buildTopLevelData('byFunction', functionTeams, data);
      buildTopLevelData('byProject', projects, data);
      state.queryData['byFunction'] = functionTeams;
      state.queryData['byProject'] = projects;
      state.queryData['byAll'] = allMembers;
      state.totalMembers = allMembers.length;
      state.totalMembersAll = allMembers.length;
      state.isLoaded = true;
      state.isSingleLevel = false;

      /* end by project reversion */
      if (state.targetGroup === 'Project') {
        state.dashboardData = state.queryData['byProject'];
      } else if (state.targetGroup === 'Function') {
        state.dashboardData = state.queryData['byFunction'];
      } else {
        state.dashboardData = state.queryData['byAll'];
      }

      // clone more from backend query to show in more deep level view
      const sltProjects = cloneDeep(data['Project']);
      state.queryData['Project'] = sltProjects;
      state.queryData['Function'] = cloneDeep(data['byFunction']);
      state.queryData['ProjectAll'] = cloneDeep(data['byProject']);

      // clone a speical filter all list
      buildAllFilterData(allFilterMembers, allMembers, state.queryData['Function']);
      allFilterMembers = allMembers.concat(allFilterMembers);
      state.queryData['filterAll'] = allFilterMembers;
      buildDashBoardData(allMembers, state);
    },
  },
  getters: {
    targetGroup(state) {
      return state.targetGroup;
    },
    targetPerson(state) {
      return state.targetPerson;
    },
    breadcrumbs(state) {
      return state.breadcrumbs;
    },
    viewDepth(state) {
      return state.viewDepth;
    },
    filterLoc(state) {
      return state.filterLoc;
    },
    showPersonalDetail(state) {
      return state.showPersonalDetail;
    },
    dashboardData(state) {
      return state.dashboardData;
    },
    detailData(state) {
      return state.detailData;
    },
    timeFrom(state) {
      return state.timeFrom;
    },
    timeTo(state) {
      return state.timeTo;
    },
    timeStride(state) {
      return state.timeStride;
    },
    queryData(state) {
      return state.queryData;
    },
    isLoading(state) {
      return state.isLoading;
    },
    shouldWorkDays(state) {
      return state.shouldWorkDays;
    },
    totalMembers(state) {
      return state.totalMembers;
    },
    supplementRate(state) {
      return state.supplementRate;
    },
    abnormalRate(state) {
      return state.abnormalRate;
    },
    invalidSwipe(state) {
      return state.invalidSwipe;
    },
    teamAvgWorkHour(state) {
      return state.teamAvgWorkHour;
    },
    teamAvgOnStart(state) {
      return state.teamAvgOnStart;
    },
    teamAvgOnEnd(state) {
      return state.teamAvgOnEnd;
    },
    teamAvgShouldWorkDays(state) {
      return state.teamAvgShouldWorkDays;
    },
    gotoHome(state) {
      return state.gotoHome;
    },
    historyList(state) {
      return state.historyList;
    },
    isSingleLevel(state) {
      return state.isSingleLevel;
    },
  },
  actions: {
    async fetchAttendances({ commit }, payload) {
      try {
        commit('setLoading', true);
        const res = await axios.get(
          `${API.ATTENDANCE_DASHBOARD}?timeFrom=${payload.timeFrom}&timeTo=${payload.timeTo}&filterLoc=${payload.filterLoc}`
        );
        commit('setQueryData', res.data);
        commit('setLoading', false);
        return Promise.resolve();
      } catch (e) {
        commit('setLoading', false);
        return Promise.reject(e);
      }
    },
    async fetchCalendar({ commit }, payload) {
      try {
        console.log(`fetchCalendar ${JSON.stringify(payload)}`);
        const res = await axios.get(`${API.ATTENDANCE_CALENDAR}?$limit=10000`);
        const loc = payload.filterLoc === 'SG' ? 'SG' : 'TW';
        const workdays = res.data.data.filter(
          item =>
            item.region === loc &&
            item.holiday_type === null &&
            moment(item.day, 'YYYYMMDDhhmm') <= moment(payload.timeTo, 'YYYYMMDDhhmm') &&
            moment(item.day, 'YYYYMMDDhhmm') >
              moment(payload.timeFrom, 'YYYYMMDDhhmm').subtract(1, 'days')
        );
        commit('setShouldWorkDays', workdays.length);
        return Promise.resolve();
      } catch (e) {
        console.log(`fetchCalendar ${e}`);
        return Promise.reject(e);
      }
    },
    async fetchDetail({ commit }, payload) {
      const items = [];
      try {
        const res = await axios.get(
          `${API.ATTENDANCE_DASHBOARD}/${payload.email}/?timeFrom=${payload.timeFrom}&timeTo=${payload.timeTo}`
        );
        res.data.forEach(elem => {
          if ((elem.weekDay === 'Sunday' || elem.weekDay === 'Saturday') === false) {
            items.push({
              email: elem.officeMail,
              attDate: moment(elem.attDate).format('YYYYMMDD'),
              weekDay: elem.weekDay,
              onStart: elem.onStart,
              onEnd: elem.onEnd,
              workHour: elem.workHour,
              detailInfo: elem.detailInfo,
            });
          }
        });
        commit('setDetailData', items);
        return Promise.resolve();
      } catch (e) {
        console.log('fetchDetail fail');
        return Promise.reject(e);
      }
    },
    async updatePageById({ commit, state }, switchId) {
      //payload is route path
      try {
        const resObj = processSwitchId(switchId);
        if (resObj.member !== undefined) {
          commit('setViewDepth', 2);
          commit('setTargetPerson', resObj.member);
        } else {
          commit('setViewDepth', 0);
        }
        commit('setTargetGroup', resObj.group);

        // update UI
        commit('updateUI', resObj);

        const crumbs = buildCrumbsById(switchId);
        commit('setBreadCrumbs', crumbs);

        return Promise.resolve();
      } catch (e) {
        console.log('updatePageById fail');
        return Promise.reject(e);
      }
    },
  },
};

export default attendance;

export function processSwitchId(switchId) {
  // possible swithch Id  format
  // AICS__All | Project | Function
  // AICS__All__member-memberName
  // AICS__Function__leader team
  // AICS__Function__leader team__member-memberName
  // AICS__Function__leader team__subteam
  // AICS__Function__leader team__subteam__member-memberName
  // AICS__Function__leader team__subteam__subSubteam__member-memberName
  // AICS__Function__leader team__subteam__subSubteam__subsubSubTeam__member-memberName
  // AICS__Project__proj team
  // AICS__Project__proj team___member-memberName
  const resObj = {};
  const tokens = switchId.split('__').map(it => decodeURIComponent(it));

  resObj.head = 'AICS';
  if (tokens.length === 1) {
    resObj.group = 'All';
  }
  if (tokens.length >= 2) {
    resObj.group = tokens[1];
  }
  if (tokens.length >= 3) {
    if (resObj.group === 'All') {
      resObj.member = tokens[2].split('member-')[1];
    } else {
      resObj.team = tokens[2];
    }
  }
  if (tokens.length >= 4) {
    if (resObj.group === 'Project') {
      resObj.member = tokens[3].split('member-')[1];
    } else if (tokens[3].includes('member-')) {
      resObj.member = tokens[3].split('member-')[1];
    } else {
      resObj.subTeam = tokens[3];
    }
  }
  if (tokens.length >= 5) {
    if (tokens[4].includes('member-')) {
      resObj.member = tokens[4].split('member-')[1];
    } else {
      resObj.subSubTeam = tokens[4];
    }
  }
  if (tokens.length >= 6) {
    if (tokens[5].includes('member-')) {
      resObj.member = tokens[5].split('member-')[1];
    } else {
      resObj.subsubSubTeam = tokens[5];
    }
  }
  if (tokens.length === 7) {
    resObj.member = tokens[6].split('member-')[1];
  }

  console.log(resObj);
  return resObj;
}

export function updateCrumb(crumbs, crumb, isAdd) {
  console.log(`++ crumbs - ${JSON.stringify(crumbs)} crumb - ${JSON.stringify(crumb)}}`);
  if (isAdd) {
    crumbs[crumbs.length - 1].active = false;
    // make sure crumb is active
    crumb.active = true;
    crumbs.push(crumb);
  } else {
    let index = crumbs.findIndex(entry => entry.to.params.id === crumb.to.params.id);
    if (index === 0) {
      // set AICS/All as landing while click 1st link
      index = 1;
      crumbs[index] = {
        text: 'All',
        to: {
          name: ATTENDANCE_DASHBOARD,
          params: { id: 'AICS__All' },
        },
        active: true,
      };
    }
    crumbs.splice(index + 1, crumbs.length - index - 1); // remove tail
    crumbs[index].active = true;
  }
  console.log(`-- crumbs - ${JSON.stringify(crumbs)} crumb - ${JSON.stringify(crumb)}}`);
}

export function searchBF(root, value) {
  let current = root;
  const queue = [];
  let isFound = false;
  while (current) {
    if (current.val.alias === value || current.val.name === value) {
      isFound = true;
      break;
    }
    for (const c of current.children) {
      queue.unshift(c);
    }
    current = queue.pop();
  }
  return isFound ? current : null;
}

export function validateSubordination(node) {
  if (!node || !node.flatData) {
    console.log('called validateSubordination() w/o flatData');
    return node;
  }

  return {
    children: node.flatData.children,
    data: node.flatData.data,
    headcount: node.flatData.children.length,
    is_manager: node.is_manager,
    val: node.val,
  };
}

export function buildFunctionLeader(member) {
  if (member.children.length < 1) {
    return null;
  } else {
    let abnormalRationText = '0 %';
    if (
      typeof member.data.abnormal === 'number' &&
      typeof member.data.shouldWorkDay === 'number' &&
      member.data.shouldWorkDay !== 0
    ) {
      abnormalRationText = `${Number(
        (100 * member.data.abnormal) / member.data.shouldWorkDay
      ).toFixed(1)} %`;
    }

    return {
      name: member.val.alias,
      avgWorkHour: member.data.workHour !== null ? Number(member.data.workHour.toFixed(1)) : 0,
      avgOnStart:
        member.data.onStart !== null
          ? moment.utc(member.data.onStart * 3600 * 1000).format('HH:mm')
          : 0,
      avgOnEnd:
        member.data.onEnd !== null
          ? moment.utc(member.data.onEnd * 3600 * 1000).format('HH:mm')
          : 0,
      lateCount: member.data.late,
      earlyCount: member.data.earlyLeave,
      supplementCount: member.data.onSupplement,
      abnormalCount: member.data.abnormal,
      abnormalRation: abnormalRationText,
      workFromHome: 0,
      workDay: member.data.workDay,
      illegalSwipe: member.data.onIllegalSwipe,
      abnormal: member.data.abnormal,
      totalMember: member.headcount,
      childNum: member.children.length,
      alias: member.val.name,
      email: member.val.email,
    };
  }
}

export function buildTopLevelData(group, teams, data) {
  data[group].forEach(item => {
    let email = '';
    let alias = '';
    let teamAlias = '';
    let avgOnStartNumber = 0;
    let avgOnEndNumber = 0;
    let totalMember = 0;
    let city = '';
    if (group === 'byAll') {
      email = item.email;
      alias = item.name;
      teamAlias = item.name;
      city = item.city;
      avgOnStartNumber = item.data.onStart;
      avgOnEndNumber = item.data.onEnd;
      totalMember = 1;
    } else {
      email = item.val.email;
      alias = item.val.alias;
      teamAlias =
        item.val.name === STAFF_TEAM.LEADER
          ? 'UX/Marketing/Admin Team'
          : `${item.val.name.split('_')[0].replace(/[0-9]/g, '')}’s Team`;
      if (group === 'byProject') {
        teamAlias = item.val.name;
      }
      totalMember = item.headcount;
    }
    const groupNumber = item.headcount > 0 ? item.headcount : 1;
    const avgShouldWorkDay = item.data.shouldWorkDay / groupNumber;
    const avgAbnormalDay = item.data.abnormal / groupNumber;
    teams.push({
      name: teamAlias,
      avgWorkHour: item.data.workHour !== null ? Number(item.data.workHour.toFixed(1)) : 0,
      avgOnStart:
        item.data.onStart !== null
          ? moment.utc(item.data.onStart * 3600 * 1000).format('HH:mm')
          : 0,
      avgOnEnd:
        item.data.onEnd !== null ? moment.utc(item.data.onEnd * 3600 * 1000).format('HH:mm') : 0,
      avgOnStartNumber,
      avgOnEndNumber,
      lateCount: item.data.late,
      earlyCount: item.data.earlyLeave,
      supplementCount: item.data.onSupplement,
      abnormalRation:
        item.data.shouldWorkDay !== null && item.data.shouldWorkDay !== 0
          ? `${Number((100 * item.data.abnormal) / item.data.shouldWorkDay).toFixed(1)} %`
          : '0 %',
      workFromHome: 0,
      workDay: item.data.workDay,
      illegalSwipe: item.data.onIllegalSwipe,
      abnormal: item.data.abnormal,
      totalMember,
      shouldWorkDay: avgShouldWorkDay !== 0 ? avgShouldWorkDay.toFixed(1) : 0,
      abnormalCount: avgAbnormalDay !== 0 ? avgAbnormalDay.toFixed(1) : 0,
      childNum: totalMember,
      alias,
      email,
      city,
    });
  });
}

export function buildAllFilterData(allFilterMembers, allMembers, functions) {
  // clone a speical filter all list
  const filterAll = [];
  for (const node of functions) {
    if (node.val.name !== 'SLT') {
      filterAll.push(node);
      node.children.forEach(elem => {
        filterAll.push(elem);
        elem.children.forEach(n => {
          filterAll.push(n);
        });
      });
    }
  }
  const unmatchList = filterAll.filter(
    node => allMembers.filter(entry => entry.name === node.val.name).length === 0
  );

  for (const node of unmatchList) {
    const avgShouldWorkDay =
      node.data.shouldWorkDay !== null && node.data.shouldWorkDay !== 0
        ? Number(node.data.shouldWorkDay).toFixed(1)
        : 0;
    const avgAbnormalDay =
      node.data.abnormal !== null && node.data.abnormal !== 0
        ? Number(node.data.abnormal).toFixed(1)
        : 0;
    allFilterMembers.push(normalizedData(node, avgShouldWorkDay, avgAbnormalDay));
  }
}
export function normalizedData(node, avgShouldWorkDay, avgAbnormalDay) {
  return {
    name: node.val.name,
    avgWorkHour: node.data.workHour !== null ? Number(node.data.workHour.toFixed(1)) : 0,
    avgOnStart:
      node.data.onStart !== null ? moment.utc(node.data.onStart * 3600 * 1000).format('HH:mm') : 0,
    avgOnStartNumber: node.data.onStart !== null ? node.data.onStart : 0,
    avgOnEnd:
      node.data.onEnd !== null ? moment.utc(node.data.onEnd * 3600 * 1000).format('HH:mm') : 0,
    avgOnEndNumber: node.data.onEnd !== null ? node.data.onEnd : 0,
    lateCount: node.data.late,
    earlyCount: node.data.earlyLeave,
    supplementCount: node.data.onSupplement,
    abnormalRation:
      node.data.shouldWorkDay !== null && node.data.shouldWorkDay !== 0
        ? `${Number((100 * node.data.abnormal) / node.data.shouldWorkDay).toFixed(1)} %`
        : '0 %',
    workFromHome: 0, //item.data.workDay,
    workDay: node.data.workDay,
    illegalSwipe: node.data.onIllegalSwipe,
    abnormal: node.data.abnormal,
    shouldWorkDay: avgShouldWorkDay,
    abnormalCount: avgAbnormalDay,
    alias: node.val.name,
    email: node.val.email === 'undefined' ? null : node.val.email,
    city: node.val.city,
  };
}

export function buildDashBoardData(allMembers, state) {
  let shouldWorkDay = 0;
  let shouldWorkDayValid = 0;
  let supplementCount = 0;
  let invalidSwipe = 0;
  let abnormal = 0;
  let teamHourWork = 0;
  let teamHourWorkValid = 0;
  let teamAvgOnStart = 0;
  let teamOnStartValid = 0;
  let teamAvgOnEnd = 0;
  let teamOnEndValid = 0;

  for (const member of allMembers) {
    supplementCount = supplementCount + parseFloat(member.supplementCount);
    invalidSwipe = invalidSwipe + parseFloat(member.illegalSwipe);
    abnormal = abnormal + parseFloat(member.abnormal);

    if (member.shouldWorkDay !== 0) {
      shouldWorkDayValid = shouldWorkDayValid + 1;
    }
    shouldWorkDay = shouldWorkDay + parseFloat(member.shouldWorkDay);

    if (member.avgWorkHour !== 0) {
      teamHourWorkValid = teamHourWorkValid + 1;
    }
    teamHourWork = teamHourWork + parseFloat(member.avgWorkHour);

    if (member.avgOnStartNumber !== 0) {
      teamOnStartValid = teamOnStartValid + 1;
    }
    teamAvgOnStart = teamAvgOnStart + parseFloat(member.avgOnStartNumber);

    if (member.avgOnEndNumber !== 0) {
      teamOnEndValid = teamOnEndValid + 1;
    }
    teamAvgOnEnd = teamAvgOnEnd + parseFloat(member.avgOnEndNumber);
  }
  shouldWorkDay = shouldWorkDay === 0 ? 1 : shouldWorkDay;

  state.abnormalRate = Number((100 * abnormal) / shouldWorkDay).toFixed(1);
  state.invalidSwipe = Number((100 * invalidSwipe) / shouldWorkDay).toFixed(0);
  state.supplementRate = Number((100 * supplementCount) / shouldWorkDay).toFixed(0);
  teamHourWorkValid = teamHourWorkValid === 0 ? 1 : teamHourWorkValid;
  state.teamAvgWorkHour = (teamHourWork / teamHourWorkValid).toFixed(1);
  teamOnStartValid = teamOnStartValid === 0 ? 1 : teamOnStartValid;
  teamOnEndValid = teamOnEndValid === 0 ? 1 : teamOnEndValid;
  state.teamAvgOnStart = moment
    .utc((teamAvgOnStart / teamOnStartValid) * 3600 * 1000)
    .format('HH:mm');
  state.teamAvgOnEnd = moment.utc((teamAvgOnEnd / teamOnEndValid) * 3600 * 1000).format('HH:mm');
  shouldWorkDayValid = shouldWorkDayValid === 0 ? 1 : shouldWorkDayValid;
  state.teamAvgShouldWorkDays = (shouldWorkDay / shouldWorkDayValid).toFixed(1);
  state.abnormalRateAll = state.abnormalRate;
  state.invalidSwipeAll = state.invalidSwipe;
  state.supplementRateAll = state.supplementRate;
  state.teamAvgWorkHourAll = state.teamAvgWorkHour;
  state.teamAvgOnStartAll = state.teamAvgOnStart;
  state.teamAvgOnEndAll = state.teamAvgOnEnd;
  state.teamAvgShouldWorkDaysAll = state.teamAvgShouldWorkDays;
}
export function moveTopDashBoardData(state) {
  state.abnormalRate = state.abnormalRateAll;
  state.invalidSwipe = state.invalidSwipeAll;
  state.supplementRate = state.supplementRateAll;
  state.totalMembers = state.totalMembersAll;
  state.teamAvgWorkHour = state.teamAvgWorkHourAll;
  state.teamAvgShouldWorkDays = state.teamAvgShouldWorkDaysAll;
  state.teamAvgOnStart = state.teamAvgOnStartAll;
  state.teamAvgOnEnd = state.teamAvgOnEndAll;
}
// possible swithch Id  format
// AICS__All | Project | Function
// AICS__All__member-memberName
// AICS__Function__leader team
// AICS__Function__leader team__member-memberName
// AICS__Function__leader team__subteam
// AICS__Function__leader team__subteam__member-memberName
// AICS__Function__leader team__subteam__subSubteam__member-memberName
// AICS__Function__leader team__subteam__subSubteam__subsubSubTeam__member-memberName
// AICS__Project__proj team
// AICS__Project__proj team___member-memberName
export function buildCrumbsById(switchId) {
  const resObj = processSwitchId(switchId);
  const crumbs = [];
  const createCrumb = (alias, next) => {
    return {
      text: `${alias}`,
      to: {
        name: ATTENDANCE_DASHBOARD,
        params: { id: `${next}` },
      },
      active: false,
    };
  };
  const tokens = switchId.split('__');
  crumbs.push(createCrumb('AICS', 'AICS'));
  crumbs.push(createCrumb(resObj.group, `AICS__${resObj.group}`));
  if (tokens.length >= 3) {
    if (tokens.length === 3 && tokens[2].includes('member-')) {
      crumbs.push(createCrumb(resObj.member, `AICS__${resObj.group}__member-${resObj.member}`));
    } else {
      crumbs.push(createCrumb(resObj.team, `AICS__${resObj.group}__${resObj.team}`));
    }
  }
  if (tokens.length >= 4) {
    if (tokens.length === 4 && tokens[3].includes('member-')) {
      crumbs.push(
        createCrumb(resObj.member, `AICS__${resObj.group}__${resObj.team}__member-${resObj.member}`)
      );
    } else {
      crumbs.push(
        createCrumb(resObj.subTeam, `AICS__${resObj.group}__${resObj.team}__${resObj.subTeam}`)
      );
    }
  }
  if (tokens.length >= 5) {
    if (tokens.length === 5 && tokens[4].includes('member-')) {
      crumbs.push(
        createCrumb(
          resObj.member,
          `AICS__${resObj.group}__${resObj.team}__${resObj.subTeam}__member-${resObj.member}`
        )
      );
    } else {
      crumbs.push(
        createCrumb(
          resObj.subSubTeam,
          `AICS__${resObj.group}__${resObj.team}__${resObj.subTeam}__${resObj.subSubTeam}`
        )
      );
    }
  }
  if (tokens.length >= 6) {
    if (tokens.length === 6 && tokens[5].includes('member-')) {
      crumbs.push(
        createCrumb(
          resObj.member,
          `AICS__${resObj.group}__${resObj.team}__${resObj.subTeam}__${resObj.subSubTeam}__member-${resObj.member}`
        )
      );
    } else {
      crumbs.push(
        createCrumb(
          resObj.subsubSubTeam,
          `AICS__${resObj.group}__${resObj.team}__${resObj.subTeam}__${resObj.subSubTeam}__${resObj.subsubSubTeam}`
        )
      );
    }
  }
  if (tokens.length === 7) {
    crumbs.push(
      createCrumb(
        resObj.member,
        `AICS__${resObj.group}__${resObj.team}__${resObj.subTeam}__${resObj.subSubTeam}__${resObj.subsubSubTeam}__member-${resObj.member}`
      )
    );
  }
  crumbs[crumbs.length - 1].active = true;
  return crumbs;
}
