import axios from 'axios';
import moment from 'moment';
import {
  API,
  KEY,
  TOOL,
  PEER_REVIEW_MIDTIME,
  PEER_REVIEW_ENDTIME,
  PEER_REVIEW_START_PERIOD,
} from '@/utils/constant';

export default {
  timeFormat: 'YYYY/MM/DD',

  async canSendPeerReview() {
    try {
      const res = await axios.get(`${API.TOOL_ACCESS_TIME}?${KEY.NAME}=${TOOL.PEER_REVIEW}`, {
        timeout: 2000,
      });
      return res.data.can_edit;
    } catch (err) {
      console.log(`canSendPeerReview error: ${err}`);
    }
    return true;
  },

  getStartTime(time) {
    // input time return YYYY/MM/DD
    let prevEnd;
    if (moment(time).format('MM/DD') > PEER_REVIEW_MIDTIME) {
      prevEnd = `${moment(time).format('YYYY')}/${PEER_REVIEW_MIDTIME}`;
    } else {
      prevEnd = `${moment(time).format('YYYY') - 1}/${PEER_REVIEW_ENDTIME}`;
    }
    return moment(prevEnd)
      .add(1, 'day')
      .format(this.timeFormat);
  },

  getEndTime(time) {
    // input time return YYYY/MM/DD
    if (moment(time).format('MM/DD') > PEER_REVIEW_MIDTIME) {
      return `${moment(time).format('YYYY')}/${PEER_REVIEW_ENDTIME}`;
    }
    return `${moment(time).format('YYYY')}/${PEER_REVIEW_MIDTIME}`;
  },

  getTimePeriodList() {
    const periodList = [];
    let endTimeFlag = this.getEndTime(moment().format());
    while (endTimeFlag >= PEER_REVIEW_START_PERIOD) {
      const startTimeFlag = this.getStartTime(endTimeFlag);
      periodList.push({ start: startTimeFlag, end: endTimeFlag });
      endTimeFlag = moment(startTimeFlag)
        .subtract(1, 'day')
        .format(this.timeFormat);
    }
    return periodList;
  },
  getTimePeriodCollapseData(data) {
    const periodList = this.getTimePeriodList();
    return periodList.map(item => {
      item.showDetails = false;
      item.detail = data
        .filter(
          dataItem =>
            item.end >= moment(dataItem.updated_at).format(this.timeFormat) &&
            moment(dataItem.updated_at).format(this.timeFormat) > item.start
        )
        .sort((a, b) => {
          if (a.updated_at > b.updated_at) {
            return -1;
          } else if (a.updated_at < b.updated_at) {
            return 1;
          } else {
            return 0;
          }
        });
      return item;
    });
  },
};
