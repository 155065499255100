import { API, QUERY_VALUE } from '@/utils/constant';
import axios from 'axios';

const member = {
  namespaced: true,
  state: {
    dirty: true,
    members: null,
    meta: null,
    myself: null,
    memberList: null,
    memberListFH: null,
    memberListFHLeft: null,
  },
  mutations: {
    init(state, data) {
      // set up the members
      if (Array.isArray(data)) {
        state.members = data;
      } else {
        state.members = [];
      }

      // build up the meta object for hierarchy
      state.meta = new Map();
      state.members.forEach((elem, i) => {
        state.meta[elem.id] = i;
        elem.children = [];
      });

      state.members.forEach(elem => {
        if (Object.keys(state.meta).includes(elem.manager_id)) {
          state.members[state.meta[elem.manager_id]].children.push(elem);
        }
      });

      // update the dirty flag
      state.dirty = false;
    },
    invalidate(state, isDirty) {
      state.dirty = isDirty;
    },
    setMyself(state, member) {
      state.myself = member;
    },
    setMemberList(state, memberList) {
      state.memberList = memberList;
    },
    setMemberListFH(state, memberListFH) {
      state.memberListFH = memberListFH;
    },
    setMemberListFHLeft(state, memberListFHLeft) {
      state.memberListFHLeft = memberListFHLeft;
    },
  },
  getters: {
    allMembers(state) {
      return state.members;
    },
    findTaiyi(state) {
      if (!state.members) {
        return null;
      }
      return state.members.find(member => member.name === 'Taiyi_Huang');
    },
    member(state) {
      return id => {
        if (state.meta === null) {
          return null;
        }
        if (!Object.keys(state.meta).includes(id)) {
          return null;
        } else {
          return state.members[state.meta[id]];
        }
      };
    },
    manager(state, getters) {
      return id => {
        const current = getters.member(id);
        if (!current) {
          return null;
        }
        return getters.member(current.manager_id);
      };
    },
    descendants(state, getters) {
      const helper = (node, collect) => {
        collect.push(node);

        node.children.forEach(child => {
          helper(child, collect);
        });
      };

      return id => {
        const current = getters.member(id);
        if (!current) {
          return [];
        }

        const res = [];
        helper(current, res);
        res.shift();

        return res;
      };
    },
    directs(state, getters) {
      return id => {
        const current = getters.member(id);
        if (!current) {
          return [];
        }
        return current.children;
      };
    },
    memberList(state) {
      return state.memberList;
    },
    memberListFH(state) {
      return state.memberListFH;
    },
    memberListFHLeft(state) {
      return state.memberListFHLeft;
    },
    memberFromList(state) {
      return id => {
        if (state.memberList !== null) {
          return state.memberList.filter(item => item.id === id)[0];
        }
        return null;
      };
    },
    memberFromListFH(state) {
      console.warn('`memberFromListFH` is deprecated, find your memberId in `memberFromListFHLeft` instead.');
      return id => {
        if (state.memberListFH !== null) {
          return state.memberListFH.find(item => item.id === id);
        }
        return null;
      };
    },
    memberFromListFHLeft(state) {
      return id => {
        if (state.memberListFHLeft !== null) {
          return state.memberListFHLeft.find(item => item.id === id);
        }
        return null;
      };
    },
  },
  actions: {
    async fetchMembers({ commit }) {
      try {
        await axios.get(`${API.MEMBER_MANAGEMENT}?$limit=${QUERY_VALUE.MAX_LIMIT}`).then(res => {
          commit('init', res.data.data);
        });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchMemberList({ commit }) {
      try {
        await axios.get(`${API.MEMBER_LIST}?$limit=${QUERY_VALUE.MAX_LIMIT}`).then(res => {
          commit('setMemberList', res.data);
        });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchMemberListFH({ commit }) {
      try {
        await axios.get(`${API.MEMBER_LIST}?futureHire=true&$limit=${QUERY_VALUE.MAX_LIMIT}`).then(res => {
          commit('setMemberListFH', res.data);
        });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchMemberListFHLeft({ commit }) {
      try {
        await axios.get(`${API.MEMBER_LIST}?futureHire=true&left=true&$limit=${QUERY_VALUE.MAX_LIMIT}`).then(res => {
          commit('setMemberListFHLeft', res.data);
        });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};

export default member;
