import { API, QUERY_VALUE } from '@/utils/constant';
import axios from 'axios';

const project = {
  namespaced: true,
  state: {
    project: [],
    vertical: [],
    verticalPeriod: [],
  },
  actions: {
    async fetchProject({ commit }) {
      try {
        const res = await axios.get(`${API.PROJECT_MANAGEMENT}?$limit=${QUERY_VALUE.MAX_LIMIT}`);
        commit('initProject', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchVertical({ commit }) {
      try {
        const res = await axios.get(
          `${API.VERTICAL}?$limit=${QUERY_VALUE.MAX_LIMIT}&is_active=true`
        );
        commit('initVertical', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchVerticalPeriod({ commit }, payload) {
      try {
        const res = await axios.get(
          `${API.VERTICAL}?$limit=${QUERY_VALUE.MAX_LIMIT}&year=${payload.year}&quarter=${payload.quarter}`
        );
        commit('initVerticalPeriod', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  mutations: {
    initProject(state, project) {
      state.project = project;
    },
    initVertical(state, vertical) {
      state.vertical = vertical;
    },
    initVerticalPeriod(state, verticalPeriod) {
      state.verticalPeriod = verticalPeriod;
    },
  },
  getters: {
    project(state) {
      return state.project;
    },
    vertical(state) {
      return state.vertical;
    },
    verticalPeriod(state) {
      return state.verticalPeriod;
    },
  },
};

export default project;
