import { API } from '@/utils/constant';

/*
  usage:
  <b-img :src="chunkedEmployeeImg(user, 48)" :key="chunkUpdateKey"></b-img>

  computed: {
    ...mapGetters('pic', ['chunkedEmployeeImg', 'fetchChunkKey', 'chunkUpdateKey']),
  },
  methods: {
    ...mapActions('pic', ['fetchPicChunks']),
  },
  watch: {
    fetchChunkKey() {
      this.fetchPicChunks();
    },
  },
*/

const pic = {
  namespaced: true,
  state: {
    chunks: {},
    updateKey: 0,
    fetchKey: 0,
    chunksToFetch: new Set(),
    fetchingChunks: new Set(),
  },
  mutations: {
    setChunk(state, { name, val }) {
      console.log('setChunk', name);
      state.chunks[name] = val;
    },
    forceUpdate(state) {
      console.log('forceUpdate');
      state.updateKey++;
    },
  },
  getters: {
    chunkedEmployeeImg(state, getters) {
      return (item, size) => {
        if (item === null || item === undefined) {
          return getters.chunkedImg('Unknown', size);
        } else {
          return getters.chunkedImg(item.employee_id || 'Unknown', size);
        }
      };
    },
    chunkedImg(state, getters, rootState) {
      return (name, size) => {
        if (!name) {
          return require('@/assets/images/avatar-default.png');
        }
        if (size === undefined || size < 86) {
          size = 86;
        }

        const chunkName =
          typeof PicChunkRule !== 'undefined' ? PicChunkRule.getChunkName(name, size) : 'chunk';
        if (state.chunks[chunkName] === undefined) {
          if (!state.chunksToFetch.has(chunkName)) {
            state.chunksToFetch.add(chunkName);
            state.fetchKey++;
          }
          return require('@/assets/images/placeholder-white.svg');
        }
        if (state.chunks[chunkName][name] !== undefined) {
          return state.chunks[chunkName][name];
        } else {
          return `${API.PIC}/${name}?session=${rootState.auth.session}`;
        }
      };
    },
    chunkUpdateKey(state) {
      return state.updateKey;
    },
    fetchChunkKey(state) {
      return state.fetchKey;
    },
  },
  actions: {
    fetchPicChunks({ dispatch }) {
      setTimeout(() => dispatch('fetchPicChunksReal'), 300);
    },
    fetchPicChunksReal({ commit, state }) {
      if (state.chunksToFetch.size === 0) {
        return;
      }

      const fetchingChunks = [...state.chunksToFetch];
      const promiseListFetch = [];
      for (const chunkName of fetchingChunks) {
        if (state.fetchingChunks.has(chunkName)) {
          continue;
        }
        state.fetchingChunks.add(chunkName);
        state.chunksToFetch.delete(chunkName);
        const promiseFetch = this._vm.axios
          .get(`${API.PIC_CHUNK}/${chunkName}`)
          .then(res => commit('setChunk', { name: chunkName, val: res.data }));
        promiseListFetch.push(promiseFetch);
        console.log('fetching picChunk', chunkName);
      }
      if (promiseListFetch.length) {
        Promise.allSettled(promiseListFetch).then(() => {
          commit('forceUpdate');
        });
      }
    },
  },
};

function preloadChunkingRule() {
  if (!document.querySelector('#pic-chunk-rule')) {
    const tag = document.createElement('script');
    tag.id = 'pic-chunk-rule';
    tag.src = API.PIC_CHUNK_RULE;
    document.head.appendChild(tag);
  }
}
preloadChunkingRule();

export default pic;
