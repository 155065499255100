import axios from 'axios';
import VueCookie from 'vue-cookie';
import { API_PATH, URL, HEADER, COOKIE } from '@/utils/constant';

/**
 * Api helper class
 *
 * @class ApiHelper
 */
class ApiHelper {
  constructor() {
    this.createAxiosClient();
  }

  createAxiosClient() {
    this.client = axios.create({
      baseURL: URL.BACKEND,
    });

    this.client.interceptors.request.use(
      config => {
        const reqConfig = config;
        if (!config.headers[HEADER.AUTH]) {
          reqConfig.headers[HEADER.AUTH] = VueCookie.get(COOKIE.TOKEN);
        }
        return reqConfig;
      },
      error => Promise.reject(error)
    );

    // Add a response interceptor
    this.client.interceptors.response.use(
      function(response) {
        return response.data;
      },
      function(error) {
        console.error(error);
        return Promise.reject(error);
      }
    );
  }

  queryShift(params) {
    return this.client.get(`${API_PATH.SHIFT}`, { params });
  }

  queryShiftGroup() {
    return this.client.get(`${API_PATH.SHIFT_GROUP}`);
  }

  addShift(body) {
    return this.client.post(`${API_PATH.SHIFT}`, body);
  }

  addShiftGroup(body) {
    return this.client.post(`${API_PATH.SHIFT_GROUP}`, body);
  }

  addShiftGroupMember(body) {
    return this.client.post(`${API_PATH.SHIFT_GROUP_MEMBER}`, body);
  }

  deleteShiftGroup(id, data) {
    return this.client.delete(`${API_PATH.SHIFT_GROUP}/${id}`, { params: { data } });
  }

  deleteShiftGroupMember(id, data) {
    return this.client.delete(`${API_PATH.SHIFT_GROUP_MEMBER}`, { params: { data } });
  }

  deleteShift(id, data) {
    return this.client.delete(`${API_PATH.SHIFT}/${id}`, { params: { shiftId: data } });
  }

  patchShift(body) {
    return this.client.patch(`${API_PATH.SHIFT}/`, body);
  }

  patchShiftGroupMember(body) {
    return this.client.patch(`${API_PATH.SHIFT_GROUP_MEMBER}/`, body);
  }

  patchShiftGroup(id, body) {
    return this.client.patch(`${API_PATH.SHIFT_GROUP}/${id}`, body);
  }

  queryVertical() {
    return this.client.get(`${API_PATH.VERTICAL}`);
  }

  queryMember(all = false, limit = 200) {
    return this.client.get(`${API_PATH.SHIFT_MEMBER_MANAGEMENT}?$limit=${limit}&all=${all}`, {});
  }

  queryOperationTeam(limit = 200) {
    return this.client.get(`${API_PATH.OPERATION_TEAM}?$limit=${limit}`, {});
  }

  suppressAlert(id, data) {
    return this.client.put(`${API_PATH.SUPPRESS_ALERT}/${id}`, data);
  }

  listAlerts() {
    return this.client.get(API_PATH.SUPPRESS_ALERT);
  }
  createAccount(data) {
    return this.client.post(API_PATH.CREATE_ACCOUNT, data);
  }

  createSelfAssessment(data) {
    return this.client.post(API_PATH.SELF_ASSESSMENT, data);
  }

  findSelfAssessment() {
    return this.client.get(`${API_PATH.SELF_ASSESSMENT}`);
  }

  patchSelfAssessment(id, data) {
    return this.client.patch(`${API_PATH.SELF_ASSESSMENT}/${id}`, data);
  }

  canEditSelfAssessment() {
    return this.client.get(API_PATH.SELF_ASSESSMENT_STATUS);
  }
  listSelfAssessment(data) {
    const { projectId, managerId, memberId, prev } = data;
    let query = '';
    if (projectId) {
      query = `?projectId=${projectId}`;
    } else if (managerId) {
      query = `?managerId=${managerId}`;
    } else {
      query = `?memberId=${memberId}`;
    }
    if (prev) {
      query = query.concat(`&prev=${prev}`);
    }
    return this.client.get(`${API_PATH.SELF_ASSESSMENT_MANAGE}${query}`);
  }
}

export default ApiHelper;
