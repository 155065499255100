import { API, RECRUITING, INTERVIEW_PROCESS } from '@/utils/constant';
import axios from 'axios';
import _ from 'lodash';
import utilsRecruiting from '@/utils/recruiting';

const recruiting = {
  namespaced: true,

  // ============================================================================
  // =                                   State                                  =
  // ============================================================================
  state: {
    /* --------------------[ candidates ]-------------------- */
    // candidates table
    candidates: [],
    candidates_total: 0,
    candidates_page: 1,
    candidates_sort: null,
    candidates_search: {
      name: null,
      source: [],
      location: [],
      education: [],
      position: [],
      status: [],
      date_from: null,
      date_to: null,
      is_set_default: true,
    },
    candidates_profile: [],

    // candidate info & form
    candidate: null,
    candidate_form: null,
    candidate_form_init: {
      first_name: null,
      last_name: null,
      cname: null,
      gender: null,
      location: null,
      position: null,
      source_name: null,
      source_extension: null,
      url: null,
      note: null,
      education: [],
      experience: [],
      attachment: {
        info: [], // already uploaded files
        files_upload: [],
        files_delete: [],
      },
    },

    /* --------------------[ process ]-------------------- */
    process_form: null,
    process_form_init: {
      status: null,
      note: '',
      remote: false,
      report: [],
      next_stage_date: null,
    },

    /* --------------------[ reports ]-------------------- */
    reports: [],
    report: null,
    report_form: null,
    report_form_init: {
      assessment: null,
      strength: null,
      weakness: null,
      summary: null,
      score: null,
      decision: null,
      exact_start_time: null,
      exact_end_time: null,
      full_report: null,
    },

    /* --------------------[ options ]-------------------- */
    options_position: [],
    options_degree: [],
    options_school: [],
    options_interview_status: [],
    options_report_status: [],
    options_decision: [],
    options_source: [],
    /* --------------------[ post-hire ]-------------------- */
    post_hires_followup: [],
    post_hires_summary: [],
    post_hires_actions: [],
    post_hires_status: [],

    /* --------------------[ misc. ]-------------------- */
    roles: [],
    is_valid: true,
  },

  // ============================================================================
  // =                                 Mutations                                =
  // ============================================================================
  mutations: {
    /* --------------------[ candidates table ]-------------------- */
    setCandidates(state, candidates) {
      state.candidates = candidates;
    },
    clearCandidates(state) {
      state.candidates = [];
    },
    setCandidatesTotal(state, candidates_total) {
      state.candidates_total = candidates_total;
    },
    setCandidatesPage(state, candidates_page) {
      state.candidates_page = candidates_page;
    },
    setCandidatesSort(state, candidates_sort) {
      state.candidates_sort = candidates_sort;
    },
    setCandidatesSearch(state, candidates_search) {
      state.candidates_search = candidates_search;
    },
    setCandidatesProfile(state, candidates_profile) {
      state.candidates_profile = candidates_profile;
    },
    clearCandidatesProfile(state) {
      state.candidates_profile = [];
    },
    clearCandidatesPageSortSearch(state) {
      state.candidates_page = 1;
      state.candidates_sort = null;
      state.candidates_search = {
        name: null,
        location: [],
        education: [],
        position: [],
        status: [],
        source: [],
        date_from: null,
        date_to: null,
        is_set_default: true,
      };
    },

    /* --------------------[ candidates info & form ]-------------------- */
    initCandidate(state, payload) {
      // set candidate state
      const candidate = payload.candidate;
      const candidate_sorted = payload.candidate_sorted;
      state.candidate = candidate;
      state.candidate.experience_sorted = JSON.parse(JSON.stringify(candidate_sorted.experience));
      state.candidate.experience_sorted.forEach(function(experience) {
        experience.start_month = utilsRecruiting.numberToMonth(experience.start_month);
        experience.end_month = utilsRecruiting.numberToMonth(experience.end_month);
      });

      // set candidate form state
      state.candidate_form = {
        first_name: state.candidate.first_name,
        last_name: state.candidate.last_name,
        cname: state.candidate.cname,
        gender: state.candidate.gender,
        location: state.candidate.location,
        position: state.candidate.position,
        source_name: state.candidate.source_name,
        source_extension: state.candidate.source_extension,
        url: state.candidate.url,
        note: state.candidate.note,
        education: [],
        experience: [],
        attachment: {
          info: state.candidate.attachment,
          files_upload: [],
          files_delete: [],
        },
      };

      // set education (convert format of month and split school & department)
      state.candidate.education.forEach(function(education) {
        const start_month = utilsRecruiting.numberToMonth(education.start_month);
        const end_month = utilsRecruiting.numberToMonth(education.end_month);

        let school = null;
        let school_name = null;
        if (RECRUITING.OPTIONS_SCHOOL.includes(education.school_name)) {
          school = education.school_name;
        } else if (education.school_name) {
          school = 'Others';
          school_name = education.school_name;
        }

        const degree = !education.degree ? 'Others' : education.degree;
        const degree_extension = education.degree_extension;
        education.degree = !education.degree ? degree_extension : degree;

        let list_department = [];
        let list_department_ext = [];
        if (education.department.length <= 0) {
          list_department = [null];
          list_department_ext = [null];
        } else {
          education.department.forEach(function(department) {
            let department_ = null;
            let department_ext = null;
            if (RECRUITING.OPTIONS_DEPARTMENT.includes(department)) {
              department_ = department;
            } else if (department) {
              department_ = 'Others';
              department_ext = department;
            }
            list_department.push(department_);
            list_department_ext.push(department_ext);
          });
        }

        state.candidate_form.education.push({
          degree,
          degree_extension,
          school,
          school_name,
          department: list_department,
          department_ext: list_department_ext,
          start_year: education.start_year,
          start_month,
          end_year: education.end_year,
          end_month,
        });
        education.start_month = start_month;
        education.end_month = end_month;
      });
      if (state.candidate.education.length <= 0) {
        state.candidate_form.education.push({
          degree: null,
          degree_extension: null,
          school: null,
          school_name: null,
          department: [null],
          department_ext: [null],
          start_year: null,
          start_month: null,
          end_year: null,
          end_month: null,
        });
      }

      // set experience (convert format of month)
      state.candidate.experience.forEach(function(experience) {
        const list_content = [];
        experience.content.forEach(function(content) {
          const start_month = utilsRecruiting.numberToMonth(content.start_month);
          const end_month = utilsRecruiting.numberToMonth(content.end_month);
          list_content.push({
            title: content.title,
            start_year: content.start_year,
            start_month,
            end_year: content.end_year,
            end_month,
          });
          content.start_month = start_month;
          content.end_month = end_month;
        });
        state.candidate_form.experience.push({
          company: experience.company,
          content: list_content,
        });
      });
      if (state.candidate.experience.length <= 0) {
        state.candidate_form.experience.push({
          company: null,
          content: [
            {
              title: null,
              start_year: null,
              start_month: null,
              end_year: null,
              end_month: null,
            },
          ],
        });
      }

      // change datetime format form UTC to yyyy-MM-dd HH:mm:ss
      for (const i in state.candidate.process) {
        state.candidate.process[i].updatedAt = utilsRecruiting.UTC2Local(
          state.candidate.process[i].updatedAt
        );
        for (const j in state.candidate.process[i].report) {
          state.candidate.process[i].report[j].updatedAt = utilsRecruiting.UTC2Local(
            state.candidate.process[i].report[j].updatedAt
          );
        }
        if (
          state.candidate.process[i].status === INTERVIEW_PROCESS.HIRE &&
          state.candidate.process[i].report[0] &&
          state.candidate.process[i].report[0].assignee
        ) {
          state.candidate.hire_manager = state.candidate.process[i].report[0].assignee;
        }
      }

      // remove recordings from attachment
      state.candidate.recordings = state.candidate.attachment
        ? state.candidate.attachment.filter(f => utilsRecruiting.isRecordingFile(f))
        : [];
      state.candidate.attachment = _.difference(
        state.candidate.attachment,
        state.candidate.recordings
      );
    },
    clearCandidate(state) {
      state.candidate = null;
      state.candidate_form = JSON.parse(JSON.stringify(state.candidate_form_init));
    },
    addEducation(state) {
      state.candidate_form.education.push({
        degree: null,
        degree_extension: null,
        school: null,
        school_name: null,
        department: [null],
        department_ext: [null],
        start_year: null,
        start_month: null,
        end_year: null,
        end_month: null,
      });
    },
    addEducationDepartment(state, index) {
      state.candidate_form.education[index].department.push(null);
      state.candidate_form.education[index].department_ext.push(null);
    },
    addExperience(state) {
      state.candidate_form.experience.push({
        company: null,
        content: [
          {
            title: null,
            start_year: null,
            start_month: null,
            end_year: null,
            end_month: null,
          },
        ],
      });
    },
    addExperienceTitle(state, index) {
      state.candidate_form.experience[index].content.push({
        title: null,
        start_year: null,
        start_month: null,
        end_year: null,
        end_month: null,
      });
    },
    deleteEducation(state, index) {
      state.candidate_form.education.splice(index, 1);
    },
    deleteEducationDepartment(state, payload) {
      state.candidate_form.education[payload.index1].department.splice(payload.index2, 1);
      state.candidate_form.education[payload.index1].department_ext.splice(payload.index2, 1);
    },
    deleteExperience(state, index) {
      state.candidate_form.experience.splice(index, 1);
    },
    deleteExperienceTitle(state, payload) {
      state.candidate_form.experience[payload.index1].content.splice(payload.index2, 1);
    },

    // Process info & form
    resetProcessForm(state) {
      // reset to previous process (when select previous state)
      if (state.candidate.process.length > 0) {
        const prev_process = state.candidate.process[state.candidate.process.length - 1];
        const list_report = [];
        prev_process.report.forEach(function(report) {
          list_report.push({
            id: report.id,
            s_status: report.s_status,
            f_status: report.f_status,
            assignee: report.assignee,
            topic: report.topic,
            event_date: report.event_date,
            expected_start_time: report.expected_start_time,
            expected_end_time: report.expected_end_time,
            note: report.note,
            is_aa: report.is_aa,
          });
        });
        state.process_form = {
          status: prev_process.status,
          note: prev_process.note,
          remote: prev_process.remote,
          report: list_report,
          next_stage_date: prev_process.next_stage_date,
        };
      }
    },
    clearProcessForm(state) {
      state.process_form = JSON.parse(JSON.stringify(state.process_form_init));
    },
    addProcessFormItem(state) {
      state.process_form.report.push({
        id: null,
        s_status: null,
        f_status: null,
        assignee: null,
        topic: null,
        event_date: null,
        expected_start_time: null,
        expected_end_time: null,
        note: '',
        is_aa: false,
      });
    },

    /* --------------------[ report table, info & form ]-------------------- */
    setReports(state, reports) {
      state.reports = reports;
    },
    clearReports(state) {
      state.reports = [];
    },
    initReport(state, report) {
      state.report = report;
      state.report_form = {
        assessment: report.assessment,
        strength: report.strength,
        weakness: report.weakness,
        summary: report.summary,
        score: report.score,
        decision: report.decision,
        exact_start_time: report.exact_start_time,
        exact_end_time: report.exact_end_time,
        full_report: report.full_report,
        updated_at: report.updated_at,
      };
    },
    clearReport(state) {
      state.report = null;
      state.report_form = JSON.parse(JSON.stringify(state.report_form_init));
    },

    /* --------------------[ options ]-------------------- */
    setOptionsPosition(state, options_position) {
      state.options_position = options_position;
    },
    setOptionsDegree(state, options_degree) {
      state.options_degree = options_degree;
    },
    setOptionsSchool(state, options_school) {
      state.options_school = options_school;
    },
    setOptionsInterviewStatus(state, options_interview_status) {
      state.options_interview_status = options_interview_status;
    },
    setOptionsReportStatus(state, options_report_status) {
      state.options_report_status = options_report_status;
    },
    setOptionsDecision(state, options_decision) {
      state.options_decision = options_decision;
    },
    setOptionsSource(state, options_source) {
      state.options_source = options_source;
    },
    /* --------------------[ post-hire ]-------------------- */
    setPostHiresFollowup(state, postHires) {
      state.post_hires_followup = postHires;
    },
    clearPostHiresFollowup(state) {
      state.post_hires_followup = [];
    },
    setPostHiresSummary(state, postHires) {
      state.post_hires_summary = postHires;
    },
    clearPostHiresSummary(state) {
      state.post_hires_summary = [];
    },
    setPostHiresActions(state, actions) {
      state.post_hires_actions = actions;
    },
    setPostHiresStatus(state, status) {
      state.post_hires_status = status;
    },
    /* --------------------[ misc. ]-------------------- */
    setRoles(state, roles) {
      const list_roles = [];
      for (let i = 0; i < roles.length; i++) {
        list_roles.push(roles[i].recruiting_role);
      }
      state.roles = list_roles;
    },
    resetValid(state) {
      state.is_valid = true;
    },
    setValid(state, is_valid) {
      state.is_valid = is_valid;
    },
  },

  // ============================================================================
  // =                                  Actions                                 =
  // ============================================================================
  actions: {
    /* --------------------[ candidates ]-------------------- */
    async fetchCandidates({ commit, state }) {
      try {
        // collect search parameters
        const para = utilsRecruiting.associateCandidateSearch(state);

        await axios
          .get(`${API.RECRUITING.CANDIDATE}${para}`)
          .then(res => {
            commit('setCandidates', res.data.result);
            commit('setCandidatesTotal', res.data.size);
          })
          .catch(error => {
            console.log(error);
          });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchCandidate({ commit }, candidate_id) {
      try {
        const res = await axios.get(`${API.RECRUITING.CANDIDATE}/${candidate_id}?exp_group=true`);
        const res_sorted = await axios.get(
          `${API.RECRUITING.CANDIDATE}/${candidate_id}?exp_group=false`
        );
        commit('initCandidate', { candidate: res.data, candidate_sorted: res_sorted.data });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchCandidatesProfile({ commit, state }) {
      try {
        // collect search parameters
        const para = utilsRecruiting.associateCandidateSearch(state);
        await axios
          .get(`${API.RECRUITING.CANDIDATE_MANAGEMENT}${para}`)
          .then(res => {
            commit('setCandidatesProfile', res.data);
          })
          .catch(error => {
            console.log(error);
          });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /* --------------------[ reports ]-------------------- */
    async fetchReports({ commit, rootState }, is_post_loop = false) {
      try {
        await new Promise(r => setTimeout(r, 250)); // [?] in order to wait for memberId. use $nextTick()?
        await axios
          .get(
            `${API.RECRUITING.REPORT}?assignee=${rootState.auth.memberId}&post_loop=${is_post_loop}`
          )
          .then(res => {
            commit('setReports', res.data);
          })
          .catch(error => {
            console.log(error);
          });
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchReport({ commit }, report_id) {
      try {
        const res = await axios.get(`${API.RECRUITING.REPORT}/${report_id}`);
        commit('initReport', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    /* --------------------[ post-hire ]-------------------- */
    async fetchPostHires({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.POST_HIRE_PROCESS}`);
        commit('setPostHiresFollowup', res.data.manager_view);
        commit('setPostHiresSummary', res.data.super_admin_view);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchPostHireActions({ commit }) {
      // call to congratuation, in person meeting, phone follow up
      try {
        const res = await axios.get(`${API.RECRUITING.POST_HIRE_STAGE}`);
        commit('setPostHiresActions', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchPostHireStatus({ commit }) {
      // Start, In Process, End, Close
      try {
        const res = await axios.get(`${API.RECRUITING.POST_HIRE_STATUS}`);
        commit('setPostHiresStatus', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    /* --------------------[ options ]-------------------- */
    async fetchOptionsPosition({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_POSITION}`);
        commit('setOptionsPosition', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOptionsDegree({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_DEGREE}`);
        commit('setOptionsDegree', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOptionsSource({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_SOURCE}`);
        commit('setOptionsSource', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOptionsSchool({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_SCHOOL}`);
        commit('setOptionsSchool', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOptionsInterviewStatus({ commit }, para_get = '') {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_INTERVIEW_STATUS}${para_get}`);
        commit('setOptionsInterviewStatus', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOptionsReportStatus({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_REPORT_STATUS}`);
        commit('setOptionsReportStatus', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOptionsDecision({ commit }, report_id) {
      try {
        const res = await axios.get(`${API.RECRUITING.OPTIONS_DECISION}/?record_id=${report_id}`);
        commit('setOptionsDecision', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },

    /* --------------------[ misc. ]-------------------- */
    async fetchRoles({ commit }) {
      try {
        const res = await axios.get(`${API.RECRUITING.TEAM}`);
        commit('setRoles', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },

  // ============================================================================
  // =                                  Getters                                 =
  // ============================================================================
  getters: {
    getCandidateTableInfo(state) {
      const list_candidate = [];
      for (const i in state.candidates) {
        const process_start_at = !state.candidates[i].process_start_at
          ? ''
          : state.candidates[i].process_start_at.replace(/-/g, '/');
        const process_end_at = !state.candidates[i].process_end_at
          ? ''
          : state.candidates[i].process_end_at.replace(/-/g, '/');
        const process_date =
          process_start_at === process_end_at
            ? process_start_at
            : `${process_start_at} - ${process_end_at}`;
        list_candidate.push({
          candidate_id: state.candidates[i].id,
          name: `${state.candidates[i].first_name} ${state.candidates[i].last_name}`,
          cname: state.candidates[i].cname,
          degree: state.candidates[i].degree,
          position: state.candidates[i].position,
          source: state.candidates[i].source,
          location: state.candidates[i].location,
          process: state.candidates[i].process,
          score: state.candidates[i].score,
          note: state.candidates[i].note,
          process_date,
        });
      }
      return list_candidate;
    },
    getReportTableInfo(state) {
      const list_report = [];
      for (const i in state.reports) {
        const event_date = state.reports[i].event_date ? state.reports[i].event_date : 'yyyy/mm/dd';
        const expected_start_time = state.reports[i].expected_start_time
          ? state.reports[i].expected_start_time
          : 'hh:mi';
        const expected_end_time = state.reports[i].expected_end_time
          ? state.reports[i].expected_end_time
          : 'hh:mi';
        list_report.push({
          report_id: state.reports[i].id,
          process_status: state.reports[i].process,
          name: `${state.reports[i].first_name} ${state.reports[i].last_name}`,
          cname: state.reports[i].cname,
          degree: state.reports[i].degree,
          applied_position: state.reports[i].position,
          attachments: state.reports[i].attachment_list
            ? state.reports[i].attachment_list.filter(
                f => !utilsRecruiting.isRecordingFile(JSON.parse(f))
              )
            : [],
          topic: state.reports[i].topic,
          interview_time: `${event_date} ${expected_start_time}-${expected_end_time}`,
          action: null,
          report_status: state.reports[i].status,
        });
      }
      return list_report;
    },

    getCandidatePayload(state) {
      // education
      const list_education = [];
      let list_department = [];
      state.candidate_form.education.forEach(function(education) {
        // get department
        list_department = [];
        for (const i in education.department) {
          if (education.department[i] || education.department_ext[i]) {
            const department =
              education.department[i] === 'Others'
                ? education.department_ext[i]
                : education.department[i];
            list_department.push(department);
          }
        }
        // skip empty item
        if (
          education.degree ||
          education.degree_extension ||
          education.school ||
          education.school_name ||
          education.start_year ||
          education.start_month ||
          education.end_year ||
          education.end_month ||
          list_department.length > 0
        ) {
          // collect an education
          list_education.push({
            degree: education.degree === 'Others' ? null : education.degree,
            degree_extension: education.degree_extension,
            school_name: education.school === 'Others' ? education.school_name : education.school,
            department: list_department,
            start_year: education.start_year,
            start_month: utilsRecruiting.monthToNumber(education.start_month),
            end_year: education.end_year,
            end_month: utilsRecruiting.monthToNumber(education.end_month),
          });
        }
      });

      // experience
      const list_experience = [];
      let list_content = [];
      for (const i in state.candidate_form.experience) {
        list_content = [];
        state.candidate_form.experience[i].content.forEach(function(content) {
          // skip empty item
          if (
            content.title ||
            content.start_year ||
            content.start_month ||
            content.end_year ||
            content.end_month
          ) {
            // convert format
            list_content.push({
              title: content.title,
              start_year: content.start_year,
              start_month: utilsRecruiting.monthToNumber(content.start_month),
              end_year: content.end_year,
              end_month: utilsRecruiting.monthToNumber(content.end_month),
            });
          }
        });
        if (state.candidate_form.experience[i].company || list_content.length > 0) {
          list_experience.push({
            company: state.candidate_form.experience[i].company,
            content: list_content,
          });
        }
      }

      // collect payload
      return {
        first_name: state.candidate_form.first_name,
        last_name: state.candidate_form.last_name,
        cname: state.candidate_form.cname,
        gender: state.candidate_form.gender,
        location: state.candidate_form.location,
        source_name: state.candidate_form.source_name,
        source_extension: state.candidate_form.source_extension,
        url: state.candidate_form.url,
        resume_url: '',
        note: state.candidate_form.note,
        position: state.candidate_form.position,
        education: list_education,
        experience: list_experience,
      };
    },
    getAttachmentDeletePara(state) {
      // convert attachment id list to url parameter
      let delete_para = '?';
      for (const i in state.candidate_form.attachment.files_delete) {
        delete_para = `${delete_para}files[${i}]=${state.candidate_form.attachment.files_delete[i]}&`;
      }
      delete_para = delete_para.slice(0, -1);
      return delete_para;
    },
    getAttachmentUploadFormData(state) {
      const upload_form_data = new FormData();
      for (const i in state.candidate_form.attachment.files_upload) {
        upload_form_data.append('files', state.candidate_form.attachment.files_upload[i]);
      }
      return upload_form_data;
    },
    getPostHiresFollowup(state) {
      const result = [];
      state.post_hires_followup.forEach(element => {
        const copy = Object.assign({}, element);
        copy.followup_name = `${element.first_name} ${element.last_name}`;
        copy.position = `${copy.position || ''} (${copy.location || ''})`;
        result.push(copy);
      });
      return result;
    },
    getPostHiresSummary(state) {
      const result = [];
      state.post_hires_summary.forEach(element => {
        const copy = Object.assign({}, element);
        copy.position = `${copy.position || ''} (${copy.location || ''})`;
        copy.summary_name = `${element.first_name} ${element.last_name}`;
        result.push(copy);
      });
      return result;
    },
    getPostHiresActions(state) {
      return [...state.post_hires_actions];
    },
    getPostHiresStatus(state) {
      return [...state.post_hires_status];
    },
    getProcessPayload: state => is_put => {
      // is_put: true -> update current process, false -> append a new process
      const list_report = [];
      state.process_form.report.forEach(function(report) {
        if (
          report.assignee ||
          report.topic ||
          report.event_date ||
          report.expected_start_time ||
          report.expected_end_time ||
          report.note
        ) {
          const report_payload = {
            assignee: report.assignee,
            topic: report.topic,
            event_date: report.event_date,
            expected_start_time: report.expected_start_time,
            expected_end_time: report.expected_end_time,
            is_aa: Boolean(report.is_aa),
            note: report.note,
          };
          if (is_put) {
            // retain the state of reports
            if (report.id) {
              report_payload.id = report.id;
              report_payload.s_status = report.s_status;
              report_payload.f_status = report.f_status;
            } else {
              report_payload.s_status = 'Start';
              report_payload.f_status = 'Start';
            }
          }
          list_report.push(report_payload);
        }
      });
      return {
        name: state.process_form.status,
        note: state.process_form.note,
        next_stage_date: state.process_form.next_stage_date,
        remote: Boolean(state.process_form.remote),
        report: list_report,
      };
    },
  },
};

export default recruiting;
