import Router from 'vue-router';
const Landing = () => import('@/views/Landing.vue');
const Member = () => import('@/views/Member.vue');
const Project = () => import('@/views/Project.vue');
const GithubAuth = () => import('@/components/GithubAuth.vue');
const PeerReview = () => import('@/views/PeerReview.vue');
import {
  COOKIE,
  GITHUB,
  AD,
  OKR_ROUTER_NAME,
  RECRUITING_STRING,
  PEER_REVIEW_ROUTER_NAME,
  SELF_ASSESSMENT_ROUTER_NAME,
} from '@/utils/constant';
const Feedback = () => import('@/components/PeerReview/Feedback.vue');
const ProvideFeedback = () => import('@/components/PeerReview/ProvideFeedback.vue');
const RequestFeedback = () => import('@/components/PeerReview/RequestFeedback.vue');
const ProvideFeedbackContent = () => import('@/components/PeerReview/ProvideFeedbackContent.vue');
const ProvideFeedbackConfirm = () => import('@/components/PeerReview/ProvideFeedbackConfirm.vue');
const MyResult = () => import('@/components/PeerReview/MyResult.vue');
const SubordinateResult = () => import('@/components/PeerReview/SubordinateResult.vue');
const SelfAssessment = () => import('@/components/PeerReview/SelfAssessment.vue');
const SelfAssessmentSubmit = () => import('@/components/PeerReview/SelfAssessmentSubmit.vue');
const SelfAssessmentView = () => import('@/components/PeerReview/SelfAssessmentView.vue');
const Okr = () => import('@/views/Okr.vue');
const MyOkr = () => import('@/components/Okr/MyOkr.vue');
const AllMembers = () => import('@/components/Okr/AllMembers.vue');
const AllVerticals = () => import('@/components/Okr/AllVerticals.vue');
const DirectReports = () => import('@/components/Okr/DirectReports.vue');
const Staff = () => import('@/components/Okr/Staff.vue');
const VerticalItem = () => import('@/components/Okr/VerticalItem.vue');
const MemberItem = () => import('@/components/Okr/MemberItem.vue');
const SearchResult = () => import('@/components/Okr/SearchResult.vue');
const ShiftCalendar = () => import('@/views/Calendar.vue');
const Alert = () => import('@/views/Alert.vue');
const Account = () => import('@/views/Account.vue');
const Attendance = () => import('@/views/Attendance.vue');
import peerReviewTime from '@/utils/script/peerReviewTime';

const Recruiting = () => import('@/views/Recruiting/Recruiting.vue'); // recruiting root component
const RecruitingManageCandidate = () => import('@/views/Recruiting/ManageCandidate.vue');
const RecruitingManageReport = () => import('@/views/Recruiting/ManageReport.vue');
const RecruitingManagePostLoop = () => import('@/views/Recruiting/ManagePostLoop.vue');
const RecruitingCreateProfile = () => import('@/views/Recruiting/CreateProfile.vue');
const RecruitingCandidateInfo = () => import('@/views/Recruiting/CandidateInfo.vue');
const RecruitingWriteReport = () => import('@/views/Recruiting/WriteReport.vue');
const RecruitingWritePostLoop = () => import('@/views/Recruiting/WritePostLoop.vue');
const RecruitingFullPageInfo = () => import('@/views/Recruiting/FullPageInfo.vue');
const RecruitingPostHireList = () => import('@/views/Recruiting/PostHireList.vue');
const RecruitingWritePostHireReport = () => import('@/views/Recruiting/WritePostHireReport.vue');

const AAABootcamp = () => import('@/views/AAABootcamp/AAABootcamp.vue');
const ReportAndFeedback = () => import('@/views/AAABootcamp/ReportAndFeedback.vue');
const Evaluation = () => import('@/views/AAABootcamp/Evaluation.vue');
const SupervisorEvaluation = () => import('@/views/AAABootcamp/SupervisorEvaluation.vue');
const TraineeList = () => import('@/views/AAABootcamp/TraineeList.vue');
const TrainingProgram = () => import('@/views/AAABootcamp/TrainingProgram.vue');

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
    },
    {
      path: '/project',
      name: 'project',
      component: Project,
    },
    {
      path: '/member/:id?',
      name: 'member',
      component: Member,
    },
    {
      path: '/peer-review',
      name: 'peerReview',
      component: PeerReview,
      redirect: { name: PEER_REVIEW_ROUTER_NAME.PROVIDE_FEEDBACK },
      children: [
        {
          path: 'feedback',
          name: 'feedback',
          component: Feedback,
          beforeEnter: async (to, from, next) => {
            const canSendPeerReview = await peerReviewTime.canSendPeerReview();
            if (
              canSendPeerReview ||
              [
                PEER_REVIEW_ROUTER_NAME.MY_RESULT,
                PEER_REVIEW_ROUTER_NAME.EMPLOYEES_RESULT,
              ].includes(to.name)
            ) {
              next();
            } else {
              next({ name: PEER_REVIEW_ROUTER_NAME.MY_RESULT });
            }
          },
          children: [
            {
              path: PEER_REVIEW_ROUTER_NAME.PROVIDE_FEEDBACK,
              name: PEER_REVIEW_ROUTER_NAME.PROVIDE_FEEDBACK,
              component: ProvideFeedback,
            },
            {
              path: 'request-feedback',
              name: 'request-feedback',
              component: RequestFeedback,
            },
            {
              path: 'provide-feedback-content',
              name: 'provide-feedback-content',
              component: ProvideFeedbackContent,
            },
            {
              path: 'provide-feedback-confirm',
              name: 'provide-feedback-confirm',
              component: ProvideFeedbackConfirm,
            },
            {
              path: PEER_REVIEW_ROUTER_NAME.MY_RESULT,
              name: PEER_REVIEW_ROUTER_NAME.MY_RESULT,
              component: MyResult,
            },
            {
              path: PEER_REVIEW_ROUTER_NAME.EMPLOYEES_RESULT,
              name: PEER_REVIEW_ROUTER_NAME.EMPLOYEES_RESULT,
              component: SubordinateResult,
            },
          ],
        },
        {
          path: 'self-assessment',
          name: 'self-assessment',
          component: SelfAssessment,
          children: [
            {
              path: SELF_ASSESSMENT_ROUTER_NAME.SELF_ASSESSMENT_SUBMIT,
              name: SELF_ASSESSMENT_ROUTER_NAME.SELF_ASSESSMENT_SUBMIT,
              component: SelfAssessmentSubmit,
              props: {
                page: 'submit',
              },
            },
            {
              path: SELF_ASSESSMENT_ROUTER_NAME.SELF_ASSESSMENT_VIEW,
              name: SELF_ASSESSMENT_ROUTER_NAME.SELF_ASSESSMENT_VIEW,
              component: SelfAssessmentView,
              props: {
                page: 'view',
              },
            },
            {
              path: SELF_ASSESSMENT_ROUTER_NAME.SELF_ASSESSMENT_ARCHIVE,
              name: SELF_ASSESSMENT_ROUTER_NAME.SELF_ASSESSMENT_ARCHIVE,
              component: SelfAssessmentView,
              props: {
                page: 'view-prev',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/okr',
      name: 'okr',
      component: Okr,
      children: [
        {
          path: 'my-okr',
          name: 'my-okr',
          component: MyOkr,
        },
        {
          path: 'all-verticals',
          name: 'all-verticals',
          component: AllVerticals,
        },
        {
          path: '/okr/vertical/:id',
          name: OKR_ROUTER_NAME.VERTICAL_ITEM,
          component: VerticalItem,
        },
        {
          path: 'all-members',
          name: 'all-members',
          component: AllMembers,
        },
        {
          path: '/okr/member/direct-reports-members',
          name: 'direct-reports',
          component: DirectReports,
        },
        {
          path: '/okr/member/staff-members',
          name: 'staff',
          component: Staff,
        },
        {
          path: '/okr/member/:id',
          name: OKR_ROUTER_NAME.MEMBER_ITEM,
          component: MemberItem,
        },
        {
          path: '/okr/search-result',
          name: 'search-result',
          component: SearchResult,
        },
      ],
    },
    {
      path: '/recruiting',
      name: 'recruiting',
      component: Recruiting,
      children: [
        {
          path: 'manage-candidate',
          name: RECRUITING_STRING.ROUTE.CANDIDATE_LIST,
          component: RecruitingManageCandidate,
        },
        {
          path: 'manage-report',
          name: RECRUITING_STRING.ROUTE.INTERVIEW_LIST,
          component: RecruitingManageReport,
        },
        {
          path: 'manage-post-loop',
          name: RECRUITING_STRING.ROUTE.POST_LOOP_LIST,
          component: RecruitingManagePostLoop,
        },
        {
          path: 'create-profile',
          name: RECRUITING_STRING.ROUTE.CREATE_CANDIDATE_PROFILE,
          component: RecruitingCreateProfile,
        },
        {
          path: 'read-report/:candidateId',
          name: RECRUITING_STRING.ROUTE.CANDIDATE_INFO,
          component: RecruitingCandidateInfo,
        },
        {
          path: 'read-report/:candidateId',
          name: RECRUITING_STRING.ROUTE.CANDIDATE_INFO_FROM_POST_HIRE,
          component: RecruitingCandidateInfo,
        },
        {
          path: 'write-report/:reportId',
          name: RECRUITING_STRING.ROUTE.WRITE_INTERVIEW_REPORT,
          component: RecruitingWriteReport,
        },
        {
          path: 'write-post-loop-report/:reportId',
          name: RECRUITING_STRING.ROUTE.WRITE_POST_LOOP_REPORT,
          component: RecruitingWritePostLoop,
        },
        {
          path: 'post-hire-list',
          name: RECRUITING_STRING.ROUTE.POST_HIRE_LIST,
          component: RecruitingPostHireList,
        },
        {
          path: 'write-post-hire-report/:processId',
          name: RECRUITING_STRING.ROUTE.WRITE_POST_HIRE_REPORT,
          component: RecruitingWritePostHireReport,
        },
      ],
    },
    {
      path: '/recruiting/info/:type/:id',
      name: 'recruiting-full-page-info',
      component: RecruitingFullPageInfo,
    },
    {
      path: '/shift',
      name: 'shift',
      component: ShiftCalendar,
    },
    {
      path: '/attendance',
      name: 'attendance',
      component: Attendance,
    },
    {
      path: '/attendance/:id',
      name: 'attendance-dashboard',
      component: Attendance,
    },
    {
      path: '/alert',
      name: 'alert',
      component: Alert,
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
    },
    {
      path: '/login',
      name: 'login',
      component: Landing,
    },
    {
      path: GITHUB.CALLBACK_PATH,
      name: 'loginCallback',
      component: GithubAuth,
    },
    {
      path: AD.CALLBACK_PATH,
      name: 'AdLoginCallback',
      component: GithubAuth,
    },
    {
      path: '/bootcamp',
      component: AAABootcamp,
      children: [
        {
          path: '',
          name: 'aaaReport',
          component: ReportAndFeedback,
        },
        {
          path: ':role/feedback/:trainee',
          name: 'aaaFeedback',
          component: ReportAndFeedback,
        },
        {
          path: 'mentor/evaluation/:trainee',
          name: 'aaaEvaluation',
          component: Evaluation,
        },
        {
          path: ':role/:type?',
          name: 'aaaTraineeList',
          component: TraineeList,
        },
        {
          path: 'supervisor/evaluation/:trainee',
          name: 'aaaSupervisorEvaluation',
          component: SupervisorEvaluation,
        },
        {
          path: ':role/program/:trainee',
          name: 'aaaProgram',
          component: TrainingProgram,
        },
      ],
    },
  ],
});

function routeRecruiting(to, from, next) {
  // Taking care of recruiting routes
  if (to.name === 'recruiting') {
    router.app.$options.store.dispatch('recruiting/fetchRoles').then(res => {
      const roles = router.app.$options.store.state.recruiting.roles;
      switch (true) {
        case roles.includes(RECRUITING_STRING.ROLES.S_ADMIN):
        case roles.includes(RECRUITING_STRING.ROLES.ADMIN):
        case roles.includes(RECRUITING_STRING.ROLES.READER):
          next({ name: RECRUITING_STRING.ROUTE.CANDIDATE_LIST });
          break;
        case roles.includes(RECRUITING_STRING.ROLES.INTERVIEWER):
          next({ name: RECRUITING_STRING.ROUTE.INTERVIEW_LIST });
          break;
        case roles.includes(RECRUITING_STRING.ROLES.PL_ORGANIZER):
          next({ name: RECRUITING_STRING.ROUTE.POST_LOOP_LIST });
          break;
        default:
          next({ name: 'landing' }); // or alert("You don't have permission to access this page."); // or 404
          break;
      }
    });
  } else {
    next();
  }
}

router.beforeEach((to, from, next) => {
  // if user didn't login with github redirect to login page
  if (router.app.$cookie.get(COOKIE.TOKEN) === null) {
    if (['/', '/login', GITHUB.CALLBACK_PATH, AD.CALLBACK_PATH].includes(to.path)) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  } else if (
    to.path === '/login' ||
    to.path === GITHUB.CALLBACK_PATH ||
    to.path === AD.CALLBACK_PATH
  ) {
    next({
      path: '/',
    });
  } else if (to.path.includes('okr')) {
    // Taking care of okr routes
    if (to.name === 'okr') {
      next({ name: 'my-okr' });
    } else {
      next();
    }
  } else if (to.path.includes('recruiting')) {
    routeRecruiting(to, from, next);
    // to-do: check other permission...
  } else {
    next();
  }
});

router.afterEach((to, from, next) => {
  if (router.app.$hideLoading) {
    router.app.$hideLoading();
  }
});

export default router;
