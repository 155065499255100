import { API } from '@/utils/constant';
import axios from 'axios';

const shift = {
  namespaced: true,
  state: {
    groupList: [],
    memberList: [],
    memberMap: new Map(),
    showDate: new Date(),
    headerData: {},
  },
  actions: {
    async fetchGroups({ commit }, payload = { limit: 100 }) {
      try {
        const res = await axios.get(`${API.SHIFT_GROUP}?$limit=${payload.limit}`);
        commit('initGroupList', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchMembers({ commit }, payload = { limit: 300, all: false }) {
      try {
        const res = await axios.get(
          `${API.SHIFT_MEMBER_MANAGEMENT}?$limit=${payload.limit}&all=${payload.all}`
        );
        commit('initMemberList', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  mutations: {
    initGroupList(state, groupList) {
      state.groupList = groupList.data
        .map(group => {
          return {
            value: group.id,
            text: group.name,
          };
        })
        .sort(function(groupA, groupB) {
          const textA = groupA.text.toUpperCase();
          const textB = groupB.text.toUpperCase();
          if (textA < textB) {
            return -1;
          }
          if (textA > textB) {
            return 1;
          }
          return 0;
        });
    },
    initMemberList(state, memberList) {
      state.memberList = memberList;
    },
    setMemberMap(state, data) {
      state.groupList.forEach(group => {
        const members = state.memberList
          .filter(member => member.shift_group_id === group.value)
          .map(member => {
            return {
              value: member.id,
              text: member.name,
            };
          });
        state.memberMap.set(group.value, members);
      });
    },
    setShowDate(state, date) {
      state.showDate = date;
    },
    setHeaderData(state, data) {
      state.headerData = data;
    },
  },
  getters: {
    groupList(state) {
      return state.groupList;
    },
    memberList(state) {
      return state.memberList;
    },
    defaultGroup(state) {
      return state.groupList[0].value;
    },
    memberMap(state) {
      return state.memberMap;
    },
    showDate(state) {
      return state.showDate;
    },
    headerData(state) {
      return state.headerData;
    },
  },
};

export default shift;
