import { API, OKR_EDIT } from '@/utils/constant';
import axios from 'axios';
import moment from 'moment';
import okrTimeUtils from '@/utils/script/okrTime';

const okr = {
  namespaced: true,
  state: {
    objective: [],
    keyResult: [],
    yearSelect: `${okrTimeUtils.methods.getAicsCurrentYear()}`,
    quarterSelect: `Q${okrTimeUtils.methods.getAicsCurrentQuarter()}`,
    searchMember: null,
    note: [],
    top: false,
    lock: true,
    lockTime: null,
  },
  actions: {
    async fetchObjective({ commit }, payload) {
      try {
        const res = await axios.get(
          `${API.OBJECTIVE_MANAGEMENT}?year=${payload.year}&quarter=${payload.quarter}`
        );
        commit('initObjective', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchKeyResult({ commit }, payload) {
      try {
        const res = await axios.get(
          `${API.KEY_RESULT_MANAGEMENT}?year=${payload.year}&quarter=${payload.quarter}`
        );
        commit('initKeyResult', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchNote({ commit }, payload) {
      try {
        const res = await axios.get(
          `${API.OKR_COMMENT}?year=${payload.year}&quarter=${payload.quarter}`
        );
        commit('initNote', res.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchOkrAccessControl({ commit }) {
      try {
        const res = await axios.get(`${API.OKR_ACCESS_CONTROL}`);
        commit('initLock', res.data.lock);
        commit('initLockTime', res.data.time);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  mutations: {
    initObjective(state, objective) {
      state.objective = objective;
    },
    initKeyResult(state, keyResult) {
      state.keyResult = keyResult;
    },
    setYearSelect(state, yearSelect) {
      state.yearSelect = yearSelect;
    },
    setQuarterSelect(state, quarterSelect) {
      state.quarterSelect = quarterSelect;
    },
    setSearchMember(state, searchMember) {
      state.searchMember = searchMember;
    },
    initNote(state, note) {
      state.note = note;
    },
    setTop(state, top) {
      state.top = top;
    },
    initLock(state, lock) {
      state.lock = lock;
    },
    initLockTime(state, lockTime) {
      state.lockTime = lockTime;
    },
  },
  getters: {
    objective(state) {
      return state.objective;
    },
    keyResult(state) {
      return state.keyResult;
    },
    yearSelect(state) {
      return state.yearSelect;
    },
    quarterSelect(state) {
      return state.quarterSelect;
    },
    okrEdit(state) {
      // this year quarter or next quarter
      let nextTargetYear = `${okrTimeUtils.methods.getAicsCurrentYear()}`;
      const nextTargetQuarter = okrTimeUtils.methods.getAicsNextQuarterIndex();
      if (nextTargetQuarter === 1) {
        nextTargetYear = `${moment()
          .add(1, 'year')
          .format('YYYY')}`;
      }
      return (
        (!state.lock &&
          OKR_EDIT &&
          state.yearSelect === okrTimeUtils.methods.getAicsCurrentYear() &&
          state.quarterSelect === `Q${okrTimeUtils.methods.getAicsCurrentQuarter()}`) ||
        (state.yearSelect === nextTargetYear && state.quarterSelect === `Q${nextTargetQuarter}`)
      );
    },
    searchMember(state) {
      return state.searchMember;
    },
    note(state) {
      return state.note;
    },
    top(state) {
      return state.top;
    },
    lock(state) {
      return state.lock;
    },
    lockTime(state) {
      return state.lockTime;
    },
  },
};

export default okr;
