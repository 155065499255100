import { API, QUERY_VALUE, DEFAULT_SUPERVISORS } from '@/utils/constant';
import axios from 'axios';
import moment from 'moment';
import peerReviewTime from '../utils/script/peerReviewTime';

const peerReview = {
  namespaced: true,
  state: {
    contents: [],
    averageScore: {},
    sentList: null,
    assignment: null,
    providingAnonymous: true,
    providingRatings: {},
    providingStrength: '',
    providingWeakness: '',
    providingToMember: null,
    providingNewFeedback: false,
    invitedList: null,
    browsingHistory: [],
    myResultDot: false,
    subordinateResultDot: false,
    ratingItemsStored: null,
    poList: [],
  },
  mutations: {
    initContents(state, contents) {
      state.contents = contents;
    },
    appendContents(state, contents) {
      const delta = contents.filter(
        item => !state.contents.some(oldItem => oldItem.id === item.id)
      );
      state.contents = state.contents.concat(delta);
    },
    appendAverages(state, { start, end, averages }) {
      const newState = { ...state.averageScore };
      averages.forEach(avg => {
        if (newState[avg.comment_to] === undefined) {
          newState[avg.comment_to] = {};
        }
        newState[avg.comment_to][start] = avg.score;
      });
      state.averageScore = newState;
    },
    initSentList(state, sentList) {
      state.sentList = sentList;
    },
    initAssignment(state, assignment) {
      state.assignment = assignment;
    },
    setProvidingAnonymous(state, anonymous) {
      state.providingAnonymous = anonymous;
    },
    setProvidingRatings(state, ratings) {
      state.providingRatings = ratings ? ratings : {};
    },
    setProvidingStrength(state, strength) {
      state.providingStrength = strength;
    },
    setProvidingWeakness(state, weakness) {
      state.providingWeakness = weakness;
    },
    setProvidingToMember(state, member) {
      state.providingToMember = member;
    },
    setProvidingNewFeedback(state, isNew) {
      state.providingNewFeedback = isNew;
    },
    setProvidingReadOnly(state, isReadOnly) {
      state.providingReadOnly = isReadOnly;
    },
    initInvitedList(state, invitedList) {
      state.invitedList = invitedList;
    },
    initBrowsingHistory(state, browsingHistory) {
      state.browsingHistory = browsingHistory;
    },
    setMyResultDot(state, myResultDot) {
      state.myResultDot = myResultDot;
    },
    setSubordinateResultDot(state, subordinateResultDot) {
      state.subordinateResultDot = subordinateResultDot;
    },
    setRatingItems(state, items) {
      state.ratingItemsStored = items;
    },
    setPoList(state, list) {
      state.poList = list;
    },
  },
  getters: {
    contents(state) {
      return state.contents;
    },
    sentList(state) {
      return state.sentList;
    },
    assignment(state) {
      return state.assignment;
    },
    invitedList(state) {
      return state.invitedList;
    },
    browsingHistory(state) {
      return state.browsingHistory;
    },
    myResultDot(state) {
      return state.myResultDot;
    },
    subordinateResultDot(state) {
      return state.subordinateResultDot;
    },
    ratingItemsByKeys(state) {
      return keys =>
        state.ratingItemsStored
          ? state.ratingItemsStored.filter(item => keys.includes(item.id))
          : [];
    },
    ratingItemsRole(state) {
      return role =>
        state.ratingItemsStored
          ? state.ratingItemsStored.filter(item => item.role.role.includes(role))
          : [];
    },
    ratingRole(state) {
      return user_id => (state.poList.includes(user_id) ? 'PO' : 'default');
    },
  },
  actions: {
    async fetchContents({ commit }, payload) {
      const end =
        payload !== undefined && payload.end !== undefined
          ? payload.end
          : peerReviewTime.getEndTime(moment().format(peerReviewTime.timeFormat));
      const start =
        payload !== undefined && payload.start !== undefined
          ? payload.start
          : peerReviewTime.getStartTime(end);
      const comment_to = payload !== undefined ? payload.comment_to : undefined;
      const comment_to_query = comment_to === undefined ? '' : `&member_id=${comment_to}`;
      try {
        const fetch1 = axios
          .get(
            `${API.PEER_REVIEW_MANAGEMENT}?start=${start}&end=${end}${comment_to_query}&$limit=${QUERY_VALUE.MAX_LIMIT}`
          )
          .then(res => {
            commit('appendContents', res.data.data);
          });
        const fetch2 = axios
          .get(
            `${API.PEER_REVIEW_MANAGEMENT}?start=${start}&end=${end}${comment_to_query}&$limit=${QUERY_VALUE.MAX_LIMIT}&type=average_score`
          )
          .then(res => {
            commit('appendAverages', { start, end, averages: res.data.data });
          });
        return Promise.all([fetch1, fetch2]);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchRatingItems({ commit }) {
      try {
        const res = await axios.get(
          `${API.PEER_REVIEW_SCORE_ITEM}?$limit=${QUERY_VALUE.MAX_LIMIT}`
        );
        commit('setRatingItems', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchSentList({ commit }, payload) {
      const queryEnd =
        payload !== undefined && payload.end !== undefined ? `end=${payload.end}&` : '';
      const queryStart =
        payload !== undefined && payload.start !== undefined ? `start=${payload.start}&` : '';
      try {
        const res = await axios.get(
          `${API.PEER_REVIEW_MANAGEMENT}?${queryStart}${queryEnd}$limit=${QUERY_VALUE.MAX_LIMIT}&type=send`
        );
        commit('initSentList', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchAssignment({ commit }) {
      try {
        const res = await axios.get(`${API.ASSIGNMENT}?$limit=${QUERY_VALUE.MAX_LIMIT}`);
        commit('initAssignment', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchInvitedList({ commit }) {
      try {
        const res = await axios.get(
          `${API.PEER_REVIEW_MANAGEMENT}?$limit=${QUERY_VALUE.MAX_LIMIT}&type=receive`
        );
        commit('initInvitedList', res.data.data);
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchBrowsingHistory({ commit, state, rootState }) {
      try {
        const res = await axios.get(
          `${API.PEER_REVIEW_BROWSING_HISTORY}?$limit=${QUERY_VALUE.MAX_LIMIT}`
        );

        commit('initBrowsingHistory', res.data.data);
        // myResult unread
        commit(
          'setMyResultDot',
          !!state.contents
            .filter(data => data.comment_to === rootState.auth.memberId && data.is_finished)
            .find(
              data => !state.browsingHistory.find(history => data.id === history.peer_review_id)
            )
        );

        // subordinateResult unread
        commit(
          'setSubordinateResultDot',
          !!state.contents
            .filter(item => item.comment_to !== rootState.auth.memberId)
            .find(
              item => !state.browsingHistory.find(history => item.id === history.peer_review_id)
            )
        );
        return Promise.resolve();
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchPO({ commit, dispatch, rootGetters }) {
      await dispatch('project/fetchProject', {}, { root: true });
      const POs = rootGetters['project/project'].reduce(
        (po, prj) => (prj.is_active ? po.concat(prj.ceo) : po),
        Object.keys(DEFAULT_SUPERVISORS)
      );
      commit(
        'setPoList',
        POs.filter((v, i, a) => v && a.indexOf(v) === i)
      );
    },
  },
};

export default peerReview;
