export default {
  app: {
    aicsShift: 'Aics Shift',
    home: 'Home',
    logout: 'Log Out',
    setting: 'Setting',
  },
  home: {
    title: 'Log In',
    submit: 'Log in',
    asusADLogIn: 'Login with ASUS AD',
  },
  calendar: {
    addShift: 'Add Shift',
    deleteShift: 'Delete Shift',
    editShift: 'Edit shift',
    vertical: 'Vertical',
    person: 'Person',
    date: 'Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    memberPicker: 'Staff on Duty',
    timeRange: 'Time Range',
    level: 'Level',
    delete: 'Remove',
    apply: 'Apply',
    cancel: 'Cancel',
    deleteModalTitle: 'Remove Shift',
    deleteAlert: 'Are you sure you want to delete the shifts on',
    dragNoticeTitle: 'Shift schedule',
    dragNoticeContent: 'Drag to adjust order',
    display: 'Display',
    shiftTitleOnduty: 'on Duty',
    shiftTitleBackup: 'Backup',
    addShiftListviewText: 'Assign {max} staff accordingly',
  },
  calendarHeader: {
    createShift: '+ Create New Shift',
    today: 'Today',
  },
  attendance: {
    filterByTime: 'Select by',
    filterByLocation: 'Select Location',
    filterByGroup: 'Filter by',
    filterByPerson: 'Search by Person',
    statistics: '出勤統計',
    corpAttendanceDays: '公司有效工作日',
    attendanceDays: '有效工作日',
    day: '天',
    allTeams: 'All Teams',
    aics: 'AICS',
    abnormalTitle: '不正常出勤比例',
    badSwipeTitle: '無刷卡比例',
    supplementTitle: '補登比例',
    totalHeadCount: '總人數: ',
    dashboard: {
      name: 'Name',
      team: 'Team',
      shouldWorkDay: '有效工作日',
      avgWorkHour: '平均每日工時',
      avgOnStart: '平均上班時間',
      avgOnEnd: '平均下班時間',
      lateCount: '遲到次數',
      earlyCount: '早退次數',
      supplementCount: '補登次數',
      abnormalCount: '不正常出勤天數',
      abnormalRation: '不正常出勤比例',
      workFromHome: '在家工作',
    },
  },
  Alert: {
    apply: 'Apply',
  },
  button: {
    apply: 'Apply',
    create: 'Create Shift',
    cancel: 'Cancel',
  },
  exchangeModal: {
    title: 'Exchange Staff',
    staffOnDuty: 'Staff on Duty',
    substitute: 'Substitute',
  },
  member: {
    loadingDialog: 'loading data',
    confirmLeaveEditing: 'Leave without saving?',
    leaveConfirm: 'Leave',
  },
};
