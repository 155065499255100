import { API, AAA_CONST } from '@/utils/constant';
import axios from 'axios';
import moment from 'moment-timezone';
import Vue from 'vue';
import CONFIG from '@/config/bootcampProgram';

moment.tz.setDefault('Asia/Taipei');

const DATE_FORMAT = 'YYYY-MM-DD';

const parseContent = item => {
  if (item.content === undefined) {
    item.content = {};
  }
  return item;
};

const traineeProgramDateToString = trainee => {
  const traineeConverted = { ...trainee };
  if (traineeConverted.programs) {
    traineeConverted.programs = trainee.programs.map(pg => {
      if (pg.date) {
        return { ...pg, date: moment(pg.date).format(DATE_FORMAT) };
      } else {
        return pg;
      }
    });
  }
  return traineeConverted;
};

const aaaBootcamp = {
  namespaced: true,
  state: {
    reportItems: {},
    feedbackItems: {},
    evaluationItems: {},
    programTopics: CONFIG.programTopics,
    programCategory: CONFIG.programCategory,
    loginRoles: undefined,
    trainees: [],
    report: {},
    feedback: {},
    evaluation: {},
    calcWeekNum: date => Math.floor(moment(date).diff(moment(AAA_CONST.WEEK_EPOCH), 'weeks', true)),
    weekNumStart: weekNum => moment(AAA_CONST.WEEK_EPOCH).add(weekNum, 'weeks'),
    weekNumEnd: weekNum =>
      moment(AAA_CONST.WEEK_EPOCH)
        .add(weekNum + 1, 'weeks')
        .add(-1, 'seconds'),
  },
  mutations: {
    setConfigs(state, payload) {
      const defaultProp = item => {
        if (item.type === undefined) {
          item.type = 'textarea';
        }
        return item;
      };
      state.reportItems = payload.reportItems.map(defaultProp);
      state.feedbackItems = payload.feedbackItems.map(defaultProp);
      state.evaluationItems = payload.evaluationItems.map(defaultProp);
    },
    setLoginRoles(state, loginRoles) {
      state.loginRoles = loginRoles;
    },
    setTraineeList(state, traineeList) {
      state.trainees = traineeList;
      state.trainees.forEach(tr => {
        if (tr.programs === undefined || tr.programs === null) {
          tr.programs = [];
        }
      });
    },
    setReportAndFeedback(state, { data, trainee, date }) {
      const report = data
        .filter(datum => datum.type === 'report')
        .filter(datum => datum.to === trainee);
      const feedback = data
        .filter(datum => datum.type === 'feedback')
        .filter(datum => datum.to === trainee);
      const week = state.calcWeekNum(date);
      if (state.report[trainee] === undefined) {
        Vue.set(state.report, trainee, {});
      }
      Vue.set(state.report[trainee], week, report.map(parseContent));
      if (state.feedback[trainee] === undefined) {
        Vue.set(state.feedback, trainee, {});
      }
      Vue.set(state.feedback[trainee], week, feedback.map(parseContent));
    },
    setEvaluation(state, { data, trainee, date }) {
      const evaluation = data.filter(datum => datum.to === trainee);
      const week = state.calcWeekNum(date);
      if (state.evaluation[trainee] === undefined) {
        Vue.set(state.evaluation, trainee, {});
      }
      Vue.set(state.evaluation[trainee], week, evaluation.map(parseContent));
    },
  },
  getters: {
    isTrainee(state) {
      return state.loginRoles && state.loginRoles.includes('trainee');
    },
    isMentor(state) {
      return state.loginRoles && state.loginRoles.includes('mentor');
    },
    isSupervisor(state) {
      return state.loginRoles && state.loginRoles.includes('supervisor');
    },
    traineeList(state) {
      return state.trainees;
    },
    reportItems(state) {
      return state.reportItems;
    },
    feedbackItems(state) {
      return state.feedbackItems;
    },
    evaluationItems(state) {
      return state.evaluationItems;
    },
    programTopics(state) {
      return state.programTopics;
    },
    programCategory(state) {
      return state.programCategory;
    },
    getCategoryByTopic(state) {
      return topic => (state.programCategory.find(cat => cat.content.includes(topic)) || {}).desc;
    },
    report(state) {
      return trainee => {
        if (trainee === undefined) {
          return state.report ? state.report : {};
        } else {
          return state.report[trainee] ? state.report[trainee] : {};
        }
      };
    },
    feedback(state) {
      return trainee => {
        if (trainee === undefined) {
          return state.feedback ? state.feedback : {};
        } else {
          return state.feedback[trainee] ? state.feedback[trainee] : {};
        }
      };
    },
    evaluation(state) {
      return trainee => {
        if (trainee === undefined) {
          return state.evaluation ? state.evaluation : {};
        } else {
          return state.evaluation[trainee] ? state.evaluation[trainee] : {};
        }
      };
    },
  },
  actions: {
    fetchConfig({ commit }) {
      const getConfig = item => {
        return AAA_CONST.DEFAULT[item];
      };
      commit('setConfigs', {
        reportItems: getConfig('reportItems'),
        feedbackItems: getConfig('feedbackItems'),
        evaluationItems: getConfig('evaluationItems'),
      });
    },
    fetchLoginRoles({ commit }) {
      return axios.get(`${API.AAA_BOOTCAMP.LOGIN_ROLES}`).then(({ data }) => {
        commit('setLoginRoles', data);
      });
    },
    waitLoginRoles({ state }) {
      return new Promise((res, rej) => {
        let retry = 10 * 10;
        const wait = () => {
          if (state.loginRoles !== undefined) {
            res();
          } else {
            if (retry > 0) {
              retry--;
              setTimeout(wait, 100);
            } else {
              rej('Timeout: fetch bootcamp role');
            }
          }
        };
        wait();
      });
    },
    fetchTraineeList({ commit, state }, supervisorMode) {
      return axios.get(`${API.AAA_BOOTCAMP.MANAGEMENT}`).then(({ data }) => {
        data.data
          .filter(trainee => trainee.programs)
          .forEach(trainee => {
            if (!Array.isArray(trainee.programs)) {
              // convert old data format to new format
              const newProgramArray = [];
              Object.entries(trainee.programs).forEach(([k, v]) => {
                const idx = parseInt(k) - 1;
                const date = state
                  .weekNumStart(state.calcWeekNum(trainee.start_date) + idx)
                  .format(DATE_FORMAT);
                newProgramArray[idx] = { ...v, date };
              });
              trainee.programs = newProgramArray;
            }
            trainee.programs.forEach(pg => {
              pg.date = moment(pg.date, DATE_FORMAT).toDate();
            });
          });
        commit('setTraineeList', data.data);
      });
    },
    addTrainee({ dispatch }, { data }) {
      return axios
        .post(`${API.AAA_BOOTCAMP.MANAGEMENT}`, traineeProgramDateToString(data))
        .then(() => {
          dispatch('fetchTraineeList', true);
        });
    },
    updateTrainee({ dispatch }, { trainee_id, data }) {
      return axios
        .patch(`${API.AAA_BOOTCAMP.MANAGEMENT}/${trainee_id}`, traineeProgramDateToString(data))
        .then(() => {
          dispatch('fetchTraineeList', true);
        });
    },
    fetchReportAndFeedback({ commit, state }, { trainee_id, date }) {
      if (date === undefined) {
        date = state.weekNumStart(state.calcWeekNum());
      }
      date = moment(date).format(DATE_FORMAT);
      return axios
        .get(`${API.AAA_BOOTCAMP.CONTENT}?to=${trainee_id}&type=reportAndFeedback&date=${date}`)
        .then(({ data }) => {
          commit('setReportAndFeedback', { data: data.data, trainee: trainee_id, date });
        });
    },
    fetchEvaluation({ commit, state }, { trainee_id, date }) {
      if (date === undefined) {
        date = state.weekNumStart(state.calcWeekNum());
      }
      date = moment(date).format(DATE_FORMAT);
      return axios
        .get(`${API.AAA_BOOTCAMP.CONTENT}?to=${trainee_id}&type=evaluation&date=${date}`)
        .then(({ data }) => {
          commit('setEvaluation', { data: data.data, trainee: trainee_id, date });
        });
    },
    addContent(context, data) {
      return axios.post(`${API.AAA_BOOTCAMP.CONTENT}`, data);
    },
    updateContent(context, data) {
      return axios.patch(`${API.AAA_BOOTCAMP.CONTENT}/${data.id}`, data);
    },
  },
};

export default aaaBootcamp;
