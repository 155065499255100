import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optional
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookie from 'vue-cookie';
import Router from 'vue-router';
import App from './App.vue';
import router from './router';
import VueAnalytics from 'vue-analytics';
import store from './store';
import i18n from './plugins/i18n';
import './plugins/axios';
import './plugins/bootstrap-vue';
import './plugins/vue-dialog-simple';
import './plugins/vue-multiselect';
import './plugins/utils';
import './plugins/vue-calendar';
import './plugins/vue-json-pretty';
import './plugins/vue-perfect-scrollbar';
import './plugins/vue-timepicker';
import 'vue-search-select/dist/VueSearchSelect.css';
import './plugins/vue2-timepicker';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueCookie);
Vue.use(Router);

if (process.env.VUE_APP_GA_ID) {
  Vue.use(VueAnalytics, {id: process.env.VUE_APP_GA_ID, router});
}

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
