import Vue from 'vue';
import ApiHelper from '../utils/ApiHelper';
import constant from '../utils/constant';

export default class Utils {
  static install(vue) {
    vue.prototype.$api = new ApiHelper();
    vue.prototype.$constant = constant;
  }
}

Vue.use(Utils);
