const API = process.env.VUE_APP_API_ENDPOINT;

const TEXT_CENTER = 'text-center';
const TEXT_LEFT = 'text-left';
const ROLE_TITLES = {
  ENGINEER: 'Engineer',
  SE: 'SE',
  EM: 'EM',
  AI_RESEARCHER: 'AI Researcher',
  PM: 'PM',
  BD: 'BD',
  UX: 'UX/UI',
  INTERN: 'Intern',
};
const RECRUITING = {
  POST_HIRE_ACTIONS: {
    MANAGER_FOLLOW_UP: 'Hiring Manager follow-up',
    PRE_ONBOARDING: 'Pre-Onboarding',
  },
  ASSESSMENT: {
    PROB_AND_STAT: 'Prob & Stat',
    CODING: 'Coding',
    DATA_ANALYSIS_AND_INSIGHT: 'Data analysis & insight',
    AI_ML_SPECIALTY: 'AI/ML specialty',
    ML_INSIGHTS: 'ML insights',
    TEAM_FIT_AND_COMM: 'Team fit/Communication',
    RAW_TALENTS: 'Raw talents',
    MARKETING_STRATEGY: 'Marketing strategy',
    MARKETING_EXECUTION: 'Marketing execution',
    MARKETING_EXPERIENCE: 'Marketing experience',
    MARKETING_STRATEGY_N_EXECUTION: 'Marketing strategy & execution',
    MGT_SKILL: 'project and ppl mgt',
    BUSINESS_SENSE: 'Business sense',
    PROBLEM_SOLVING: 'Problem solving',
    PASSION: 'Passion(self-motivated, hard-working, tenacity)',
    RECRUITING: 'Recruiting',
    TEAM_FIT: 'Team fit',
    COMMUNICATION: 'Communication',
    PRODUCT_PROJECT_MANAGEMENT: 'Product/Project MGT',
    DESIGN_STRATEGY: 'Design strategy',
    DESIGN_SKILL: 'Design skills',
    GRAPHIC_UI: 'Graphic UI',
    UI_UX_DOMAIN: 'UIX domain',
    PROJECT_MANAGEMENT: 'Project Mgt',
    RECRUITING_STRATEGY: 'Recruiting strategy',
    CS_FUNDAMENTAL: 'CS fundamental (algorithm, coding)',
    RESEARCH_ABILITY: 'Research ability',
    ACADEMIC_PERFORMANCE: 'Academic performance',
    AICS_ALIGNMENT: 'AICS alignment',
    DOMAIN_KNOWLEDGE: 'Domain knowledge',
    DOMAIN_KNOWLEDGE_N_SKILLS: 'Domain knowledge & skills',
    TEST_PLAN: 'Test plan',
    LOGICAL_THINKING: 'Logical thinking',
    BUSINESS_DEVELOPMENT: 'Business development',
    PEOPLE_MANAGEMENT: 'People management',
    MISSION_MENTALITY: 'Mission mentality',
    PRESENTATION: 'Presentation',
    PRODUCT_PLANNING: 'Product planning',
    PROJECT_PEOPLE_MANAGEMENT: 'Project and People management',
    MOCKUP_IMPLEMENTATION: 'Mockup implementation',
    AUTOMATION_CODING: 'Automation coding',
    TEST_TOOLS_FAMILIARITY: 'Test tools familiarity',
    SECURITY_EXPERIENCE: 'Security experience/knowledge',
    CLOUD_EXPERIENCE: 'Cloud experience/knowledge',
    SYSTEM_DESIGN: 'System design',
    THINK_BIG: 'Think big',
    MONGODB_INSIGHTS:'MongoDB insights',
  },
  TABLE_SCHEMA: {
    NAME: 'Name',
    CHINESE_NAME: 'Chinese Name',
    LOCATION: 'Location',
    EDUCATION: 'Education',
    APPLIED_POSITION: 'Applied Position',
    SOURCE: 'Source',
    POSITION: 'Position',
    STATUS: 'Status',
    TS_SCORE: 'TS Score',
    NOTE: 'Note',
    PROCESS_DATE: 'Process Date',
    RESUME: 'Resume',
    INTERVIEW_TOPIC: 'Interview Topic',
    INTERVIEW_TIME: 'Interview Time',
    REPORT: 'Report',
    HIRING_DATE: 'Hiring Approval Date',
    TRACKING_REPORT: 'Post Hire Report',
    REPORTING_EM: 'Reporting EM',
    LATEST_ACTION: 'Latest Action',
    ACTION_DATE: 'Action Date',
    OPTIONS: 'Options',
    OFFER_DELIVERED_DATE: 'Offer Delivered Date',
    ONBOARD_DATE: 'Onboarding Date',
    OFFICE_TOUR_DATE: 'Office Tour Date',
  },
  FIELD: {
    NAME: 'name',
    CNAME: 'cname',
    LOCATION: 'location',
    DEGREE: 'degree',
    POSITION: 'position',
    SOURCE: 'source',
    PROCESS: 'process',
    SCORE: 'score',
    NOTE: 'note',
    PROCESS_DATE: 'process_date',
    PROCESS_START_AT: 'process_start_at',
    PROCESS_STATUS: 'process_status',
    APPLIED_POSITION: 'applied_position',
    ATTACHMENT: 'attachment',
    TOPIC: 'topic',
    INTERVIEW_TIME: 'interview_time',
    ACTION: 'action',
    EDUCATION: 'education',
    HIRING_DATE: 'hiring_date',
    MANAGER: 'manager',
    STAGE: 'stage',
    EVENT_DATE: 'event_date',
    STATUS: 'status',
    OPTIONS: 'options',
    FOLLOWUP_NAME: 'followup_name',
    SUMMARY_NAME: 'summary_name',
    OFFER_DELIVERED_DATE: 'offer_delivered_date',
    ONBOARD_DATE: 'onboarding_date',
    OFFICE_TOUR_DATE: 'office_tour_date',
  },
  STYLE: {
    TEXT_CENTER,
    TEXT_LEFT,
  },
};
const BASE_ASSESSMENT = {
  SDE: [
    RECRUITING.ASSESSMENT.CODING,
    RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
    RECRUITING.ASSESSMENT.COMMUNICATION,
    RECRUITING.ASSESSMENT.RAW_TALENTS,
  ],
  PHD: [
    RECRUITING.ASSESSMENT.CS_FUNDAMENTAL,
    RECRUITING.ASSESSMENT.RESEARCH_ABILITY,
    RECRUITING.ASSESSMENT.ACADEMIC_PERFORMANCE,
    RECRUITING.ASSESSMENT.AICS_ALIGNMENT,
  ],
  BIOSTATISTICIAN: [
    RECRUITING.ASSESSMENT.PROB_AND_STAT,
    RECRUITING.ASSESSMENT.CODING,
    RECRUITING.ASSESSMENT.DATA_ANALYSIS_AND_INSIGHT,
    RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
    RECRUITING.ASSESSMENT.RAW_TALENTS,
    RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE,
  ],
  MKT: [
    RECRUITING.ASSESSMENT.LOGICAL_THINKING,
    RECRUITING.ASSESSMENT.MARKETING_EXPERIENCE,
    RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
    RECRUITING.ASSESSMENT.MISSION_MENTALITY,
  ],
  PM: [
    RECRUITING.ASSESSMENT.PRESENTATION,
    RECRUITING.ASSESSMENT.LOGICAL_THINKING,
    RECRUITING.ASSESSMENT.PRODUCT_PLANNING,
    RECRUITING.ASSESSMENT.PROJECT_PEOPLE_MANAGEMENT,
    RECRUITING.ASSESSMENT.MISSION_MENTALITY,
  ],
  UX: [
    RECRUITING.ASSESSMENT.PRESENTATION,
    RECRUITING.ASSESSMENT.LOGICAL_THINKING,
    RECRUITING.ASSESSMENT.DESIGN_SKILL,
    RECRUITING.ASSESSMENT.DESIGN_STRATEGY,
    RECRUITING.ASSESSMENT.MISSION_MENTALITY,
  ],
  DS: [
    RECRUITING.ASSESSMENT.PROB_AND_STAT,
    RECRUITING.ASSESSMENT.CODING,
    RECRUITING.ASSESSMENT.DATA_ANALYSIS_AND_INSIGHT,
    RECRUITING.ASSESSMENT.AI_ML_SPECIALTY,
    RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
    RECRUITING.ASSESSMENT.RAW_TALENTS,
  ],
  RESEARCHER: [
    RECRUITING.ASSESSMENT.ML_INSIGHTS,
    RECRUITING.ASSESSMENT.RESEARCH_ABILITY,
    RECRUITING.ASSESSMENT.CODING,
    RECRUITING.ASSESSMENT.PRESENTATION,
    RECRUITING.ASSESSMENT.MISSION_MENTALITY,
  ],
  MLE: [
    RECRUITING.ASSESSMENT.CODING,
    RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
    RECRUITING.ASSESSMENT.COMMUNICATION,
    RECRUITING.ASSESSMENT.ML_INSIGHTS,
  ],
  QA: [
    RECRUITING.ASSESSMENT.AUTOMATION_CODING,
    RECRUITING.ASSESSMENT.TEST_TOOLS_FAMILIARITY,
    RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
    RECRUITING.ASSESSMENT.COMMUNICATION,
    RECRUITING.ASSESSMENT.PROJECT_MANAGEMENT,
    RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE_N_SKILLS,
    RECRUITING.ASSESSMENT.MISSION_MENTALITY,
  ],
};
const config = {
  GITHUB: {
    SCOPE: 'read:user, read:org, user:email',
    LOGIN_STATE: 'login',
    LOGIN_PATH: '/login',
    CALLBACK_PATH: '/login/callback',
    AUTH_URL: 'https://github.com/login/oauth/authorize',
  },
  COOKIE: {
    TOKEN: 'jwt',
  },
  EVENT: {
    LOGIN: 'login',
    LOGOUT: 'logout',
  },
  API: {
    AUTH: `${API}/authentication`,
    PROJECT_MANAGEMENT: `${API}/project-management`,
    PIC: `${API}/pic`,
    PIC_CHUNK: `${API}/pic-chunk`,
    PIC_CHUNK_RULE: `${API}/pic-chunk-rule.js`,
    LOCATION: `${API}/location`,
    TITLE: `${API}/title`,
    MEMBER_MANAGEMENT: `${API}/member-management`,
    MEMBER_SELFINTRO: `${API}/self-introduction`,
    MEMBER_STATUS: `${API}/member-status`,
    PEER_REVIEW_MANAGEMENT: `${API}/peer-review-management`,
    PEER_REVIEW_SCORE_ITEM: `${API}/peer-review-score-item`,
    ASSIGNMENT: `${API}/assignment`,
    PEER_REVIEW_EXPORT: `${API}/peer-review-export`,
    PEER_REVIEW_BROWSING_HISTORY: `${API}/browsing-history`,
    VERTICAL: `${API}/vertical`,
    KEY_RESULT_MANAGEMENT: `${API}/key-result-management`,
    KEY_RESULT: `${API}/key-result`,
    OBJECTIVE_MANAGEMENT: `${API}/objective-management`,
    OBJECTIVE: `${API}/objective`,
    OKR_COMMENT: `${API}/okr-comment`,
    MEMBER_LIST: `${API}/member-list`,
    OKR_ACCESS_CONTROL: `${API}/okr-status`,
    SHIFT_GROUP: `${API}/shift-group`,
    SHIFT_MEMBER_MANAGEMENT: `${API}/shift-member-management`,
    RECRUITING: {
      CANDIDATE: `${API}/candidate`,
      CANDIDATE_MANAGEMENT: `${API}/candidate-management`,
      INTERVIEW_PROCESSS_LIST: `${API}/interview-process-list`,
      REPORT: `${API}/interview-record`,
      ATTACHMENT: `${API}/candidate-file`,
      PROCESS: `${API}/interview-process`,
      OPTIONS_POSITION: `${API}/position`, // get position options
      OPTIONS_DEGREE: `${API}/academic-degree`, // get degree options
      OPTIONS_SCHOOL: `${API}/school`, // get school options
      OPTIONS_INTERVIEW_STATUS: `${API}/interview-status`, // get interview status options
      OPTIONS_REPORT_STATUS: `${API}/report-status`, // get report status options
      OPTIONS_DECISION: `${API}/interview-decision`, // get decision options
      OPTIONS_SOURCE: `${API}/interview-source`, // get source options
      TEAM: `${API}/recruiting-team`, // get member roles
      POST_HIRE_PROCESS: `${API}/post-hire-process`, // post-hire list
      POST_HIRE_RECORD: `${API}/post-hire-record`, // post-hire record
      POST_HIRE_STAGE: `${API}/post-hire-stage`, // post-hire stage
      POST_HIRE_STATUS: `${API}/post-hire-status`, // post-hire status
    },
    ATTENDANCE_DASHBOARD: `${API}/attendance-dashboard`,
    ATTENDANCE_CALENDAR: `${API}/calendar`,
    TOOL_ACCESS_TIME: `${API}/tool-access-time`,
    AAA_BOOTCAMP: {
      LOGIN_ROLES: `${API}/bootcamp-role`,
      MANAGEMENT: `${API}/bootcamp-trainee-management`,
      CONTENT: `${API}/bootcamp-content-management`,
    },
  },
  KEY_CODE: {
    UP: 38,
    DOWN: 40,
  },
  HEADER: {
    AUTH: 'Authorization',
  },
  MEMBER_STATUS_EMPLOYED: {
    ID: '21bef270-2b16-4777-8a01-67c7b4c44c4b',
    NAME: 'Employed',
  },
  SPECIAL_TITLES: [
    {
      STATUS_ID: '4bf8ffaa-39af-4469-81a6-f2e0f4284a83',
      STATUS_NAME: 'Future Hire',
      TITLE_ID: '69b970a6-57cb-4d7b-b025-25de710dbad9',
      TITLE_NAME: 'FH',
    },
  ],
  AICS_DEPARTMENT: {
    ID: '7713143b-de22-433f-bd9f-5a60ca1cf69e',
    NAME: 'AICS',
  },
  PRIVILEGE: {
    ADMIN: 'admin',
    GUEST: 'guest',
    NOBILITY: 'nobility',
    SCHEDULER: 'scheduler',
    ATTENDANCE_ADMIN: 'attendance_admin',
  },
  QUERY_VALUE: {
    MAX_LIMIT: 1000,
  },
  ORG: {
    VIEW_RATIO: 0.9,
    ZOOMIN_LIMIT: 7,
    ZOOMOUT_LIMIT: 0.01,
  },
  FILTER_TITLE: [
    'All',
    'Consultant',
    'PhD',
    'AI Researcher',
    'BD',
    'UX/UI',
    'PM',
    'Marketing PM',
    'EM',
    'Engineer',
    'SE',
    'Intern',
  ],
  FILTER_TITLE_ADMIN: ['FH'],
  FILTER_LOC: ['All', 'TP', 'SG'],
  FILTER_LOC_ADMIN: [],
  POLLING_TIME_INTERVAL: process.env.POLLING_TIME_INTERVAL || 5 * 60 * 1000,
  FREE_POOL_ID: process.env.FREE_POOL_ID || '911007bc-2367-4142-84c7-8553b7dd0caf',
  STAFF_ID: process.env.STAFF_ID || '4791f4d2-e325-437e-acb1-1aa44d788abc',
  MODAL_ADD_PROJECT: 'modal-add-project',
  SES_URL: process.env.SES_URL || 'https://sesdashboard.azurewebsites.net/login',
  AD: {
    AUTH_URL:
      process.env.AD_URL ||
      `https://login.microsoftonline.com/301f59c4-c269-4a66-8a8c-f5daab211fa3/oauth2/v2.0/authorize?client_id=8b290f5d-42f4-45f4-b308-d4985b37cb3c&response_type=code&response_mode=query&redirect_uri=${process.env.VUE_APP_FE_ENDPOINT}/ad/login/callback&state=12345&scope=offline_access%20user.read%20mail.read`,
    CALLBACK_PATH: '/ad/login/callback',
    CLIENT_ID: '8b290f5d-42f4-45f4-b308-d4985b37cb3c',
  },
  VERTICAL_COLOR_STYLE: [
    'background-color: rgba(4, 93, 233, 0.1);',
    'background-color: rgba(0, 172, 170, 0.1);',
    'background-color: rgba(139, 150, 0, 0.1);',
    'background-color: rgba(34, 0, 140, 0.1);',
    'background-color: rgba(155, 90, 0, 0.1);',
    'background-color: rgb(230, 230, 250);',
    'background-color: rgb(248, 248, 255);',
    'background-color: rgb(240, 248, 255);',
    'background-color: rgb(245, 255, 250);',
    'background-color: rgb(240, 255, 240);',
    'background-color: rgb(250, 250, 210);',
    'background-color: rgb(255, 250, 205);',
    'background-color: rgb(255, 248, 220);',
    'background-color: rgb(255, 255, 224);',
    'background-color: rgb(255, 255, 240);',
  ],
  TITLES: {
    ENGINEER: ROLE_TITLES.ENGINEER,
    SE: ROLE_TITLES.SE,
    EM: ROLE_TITLES.EM,
    AI_RESEARCHER: ROLE_TITLES.AI_RESEARCHER,
    PM: ROLE_TITLES.PM,
    BD: ROLE_TITLES.BD,
    UX: ROLE_TITLES.UX,
    INTERN: ROLE_TITLES.INTERN,
  },
  PROJECT_MEMBER_TITLES: [
    ROLE_TITLES.AI_RESEARCHER,
    ROLE_TITLES.EM,
    ROLE_TITLES.ENGINEER,
    ROLE_TITLES.SE,
    ROLE_TITLES.INTERN,
  ],
  OKR_ROUTER_NAME: {
    VERTICAL_ITEM: 'vertival-item',
    MEMBER_ITEM: 'member-item',
  },
  QUARTER_MONTH: {
    Q1: ['JAN', 'FEB', 'MAR'],
    Q2: ['APR', 'MAY', 'JUN'],
    Q3: ['JUL', 'AUG', 'SEP'],
    Q4: ['OCT', 'NOV', 'DEC'],
  },
  NEW_QUARTER_MONTH: {
    Q1: ['DEC', 'JAN', 'FEB'],
    Q2: ['MAR', 'APR', 'MAY'],
    Q3: ['JUN', 'JUL', 'AUG'],
    Q4: ['SEP', 'OCT', 'NOV'],
  },
  MODAL_EDIT_OKR: 'modal-edit-okr',
  OKR_START_DATE: {
    YEAR: process.env.OKR_START_YEAR || 2019,
    QUARTER: process.env.OKR_START_QUARTER || 4,
  },
  OKR_BUFFER_DAYS: process.env.VUE_APP_OKR_OFFSET || 3,
  STAFF_TEAM: {
    LEADER: 'Shujen_Chen',
  },
  DEFAULT_SUPERVISORS: {
    '39b5da95-3d83-49b6-b8af-3298be2fcd4b': 'Taiyi Huang',
    'a04ae98f-18e3-4ac7-ada8-a34821241aab': 'Shujen Chen',
  },
  URL: {
    BACKEND: API || 'http://localhost:8081',
  },
  PAGE: {
    HOME: 'home',
    CALENDAR: 'calendar',
    ALERT: 'alert',
  },
  ROUTE: {
    HOME: '/',
    CALENDAR: '/calendar',
    ALERT: '/alert',
  },
  API_PATH: {
    VERTICAL: '/vertical',
    SHIFT_MEMBER_MANAGEMENT: '/shift-member-management',
    OPERATION_TEAM: '/operation-team',
    PERSONNEL: '/member',
    SHIFT: '/shift',
    SHIFT_GROUP_MEMBER: '/shift-group-member',
    SHIFT_GROUP: '/shift-group',
    PIC: '/pic',
    SELF_ASSESSMENT: '/self-assessment',
    SELF_ASSESSMENT_STATUS: '/self-assessment-status',
    SELF_ASSESSMENT_MANAGE: '/self-assessment-manage',
    AUTHENTICATION: '/authentication',
    SUPPRESS_ALERT: '/sky-watcher',
    CREATE_ACCOUNT: '/apim-account',
    AUTHORIZE:
      'https://login.microsoftonline.com/301f59c4-c269-4a66-8a8c-f5daab211fa3/oauth2/v2.0/authorize',
  },
  KEY: {
    USER_TOKEN: 'user-token',
    OPERATION_TEAM: 'operationTeam',
    USER_INFO: 'user-info',
    AT: 'at',
    PERIOD: 'period',
    NAME: 'name',
  },
  STRATEGY: {
    ASUS: 'asus',
  },
  MODAL: {
    EDIT_SHIFT_MODAL: 'edit-shift-modal',
    ADD_SHIFT_MODAL: 'add-shift-modal',
    SHIFT_GROUP_MODAL: 'shift-group-modal',
    ALERT_MODAL: 'alert-modal',
    EXCHANGE_SHIFT_MODAL: 'exchange-modal',
    EDIT_GROUP_MODAL: 'edit-group-modal',
    MODAL_LOADING: 'modal-loading',
    INTERNET_ERROR: 'modal-internet-error',
    FEEDBACK_SENT: 'modal-feedback-sent',
    REQUEST_SENT: 'modal-request-sent',
    REQUEST_CONFIRM: 'modal-request-confirm',
  },
  DAY_HEADER: {
    SUN: 'SUN',
    MON: 'MON',
    TUE: 'TUE',
    WED: 'WED',
    THU: 'THU',
    FRI: 'FRI',
    SAT: 'SAT',
  },
  VUEX_STORE: {
    AUTH: 'auth',
    MEMBER: 'member',
    SHIFT: 'shift',
  },
  COLOR_PICKER: {
    OPTIONS: [
      {
        value: 'class-blue',
        hex: 'rgba(133, 213, 211, 1)',
      },
      {
        value: 'class-yellow',
        hex: 'rgba(237, 230, 123, 1)',
      },
      {
        value: 'class-orange',
        hex: 'rgba(252, 123, 96, 1)',
      },
      {
        value: 'class-green',
        hex: 'rgba(161, 206, 131, 1)',
      },
    ],
    DEFAULT_VALUE: 'class-blue',
    BACKUP_VALUE: 'class-backup',
  },
  OPTIONS: {
    DEFAULT_VERTICAL: 'operationTeam',
  },
  TOOL: {
    PEER_REVIEW: 'peer-review',
  },
  DATE_FORMAT: 'YYYY-MM-DD',
  OKR_EDIT: true,
  TAIYI_EMPLOYEE_ID: 'AA1800805',
  PEER_REVIEW_MIDTIME: '06/30',
  PEER_REVIEW_ENDTIME: '12/31',
  PEER_REVIEW_START_PERIOD: '2019/07/01',
  PEER_REVIEW_ROUTER_NAME: {
    PROVIDE_FEEDBACK: 'provide-feedback',
    MY_RESULT: 'my-result',
    EMPLOYEES_RESULT: 'employees-result',
  },
  SELF_ASSESSMENT_ROUTER_NAME: {
    SELF_ASSESSMENT_SUBMIT: 'self-assessment-submit',
    SELF_ASSESSMENT_VIEW: 'self-assessment-view',
    SELF_ASSESSMENT_ARCHIVE: 'self-assessment-archive',
  },
  RECRUITING: {
    PER_PAGE: 100,
    OPTIONS_MONTH: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    OPTIONS_SCHOOL: [
      'NTU',
      'NCTU',
      'NTHU',
      'NCKU',
      'NCCU',
      'NCU',
      'NUS',
      'Nanyang Technological Univ.',
    ],
    OPTIONS_DEGREE: ['Bachelor', 'Master', 'PhD', 'MBA'],
    OPTIONS_DEPARTMENT: [
      'CSIE',
      'EE',
      'EEE',
      'OE',
      'CommE',
      'NM',
      'BEBI',
      'IM',
      'MBA',
      'EMBA',
      'IB',
      'Fin',
      'Acc',
      'BA',
      'IE',
      'MATH',
      'Phys',
      'MSE',
    ],
    POSITION_ASSESSMENT: {
      'Data Scientist': BASE_ASSESSMENT.DS,
      'Sr. Data Scientist': BASE_ASSESSMENT.DS,
      'DS-intern': BASE_ASSESSMENT.DS,
      'DS-intern YR': BASE_ASSESSMENT.DS,
      Biostatistician: BASE_ASSESSMENT.BIOSTATISTICIAN,
      'Biostatistician-intern': BASE_ASSESSMENT.BIOSTATISTICIAN,
      'Biostatistician-intern YR': BASE_ASSESSMENT.BIOSTATISTICIAN,
      EM: [
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.AI_ML_SPECIALTY,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.RECRUITING,
      ],
      Marketing: BASE_ASSESSMENT.MKT,
      'Marketing Manager': BASE_ASSESSMENT.MKT,
      'MKT-intern': BASE_ASSESSMENT.MKT,
      'MKT-intern YR': BASE_ASSESSMENT.MKT,
      PM: BASE_ASSESSMENT.PM,
      'Sr. PM': BASE_ASSESSMENT.PM,
      'PM-intern': BASE_ASSESSMENT.PM,
      'PM-intern YR': BASE_ASSESSMENT.PM,
      BD: [
        RECRUITING.ASSESSMENT.LOGICAL_THINKING,
        RECRUITING.ASSESSMENT.BUSINESS_DEVELOPMENT,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      Sales: [
        RECRUITING.ASSESSMENT.PRODUCT_PROJECT_MANAGEMENT,
        RECRUITING.ASSESSMENT.BUSINESS_SENSE,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.TEAM_FIT,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
      ],
      UIX: BASE_ASSESSMENT.UX,
      'UX-intern': BASE_ASSESSMENT.UX,
      'UX-intern YR': BASE_ASSESSMENT.UX,
      'UIX Manager': BASE_ASSESSMENT.UX,
      SDE: BASE_ASSESSMENT.SDE,
      'SDE (3A)': BASE_ASSESSMENT.SDE,
      'SDE-intern': BASE_ASSESSMENT.SDE,
      'SDE-intern YR': BASE_ASSESSMENT.SDE,
      'Sr. SDE': BASE_ASSESSMENT.SDE,
      SRE: BASE_ASSESSMENT.SDE,
      'Security Engineer': [
        RECRUITING.ASSESSMENT.SECURITY_EXPERIENCE,
        RECRUITING.ASSESSMENT.CLOUD_EXPERIENCE,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.PROJECT_MANAGEMENT,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      'FE SDE': [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.MOCKUP_IMPLEMENTATION,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      DevOps: BASE_ASSESSMENT.SDE,
      ETL: BASE_ASSESSMENT.SDE,
      SE: [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE_N_SKILLS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      FAE: [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.AI_ML_SPECIALTY,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
      ],
      PhD: BASE_ASSESSMENT.PHD,
      'AI Researcher': BASE_ASSESSMENT.RESEARCHER,
      'AI-intern': BASE_ASSESSMENT.RESEARCHER,
      'QA Manager': BASE_ASSESSMENT.QA,
      QA: BASE_ASSESSMENT.QA,
      UR: [
        RECRUITING.ASSESSMENT.DESIGN_STRATEGY,
        RECRUITING.ASSESSMENT.GRAPHIC_UI,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.TEAM_FIT,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
      ],
      'Executive Assistant': [
        RECRUITING.ASSESSMENT.MARKETING_STRATEGY_N_EXECUTION,
        RECRUITING.ASSESSMENT.MGT_SKILL,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.PASSION,
        RECRUITING.ASSESSMENT.RECRUITING,
      ],
      MLE: BASE_ASSESSMENT.MLE,
      'MLE-intern': BASE_ASSESSMENT.MLE,
      'MLE-intern YR': BASE_ASSESSMENT.MLE,
      BA: [
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE_N_SKILLS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      SA: [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE_N_SKILLS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      DA: [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      'Director/Head of Engineering': [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.AI_ML_SPECIALTY,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.RECRUITING,
      ],
      'FE EM': [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.MOCKUP_IMPLEMENTATION,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.RECRUITING,
      ],
      'MLE EM': [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.AI_ML_SPECIALTY,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.RECRUITING,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      PJM: [
        RECRUITING.ASSESSMENT.PRESENTATION,
        RECRUITING.ASSESSMENT.LOGICAL_THINKING,
        RECRUITING.ASSESSMENT.PRODUCT_PLANNING,
        RECRUITING.ASSESSMENT.PROJECT_PEOPLE_MANAGEMENT,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      'Software Architect': [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.SYSTEM_DESIGN,
        RECRUITING.ASSESSMENT.TEAM_FIT_AND_COMM,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      'MongoDB SDE':[
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.MONGODB_INSIGHTS,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
      ],
      'Sr. FE SDE': [
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.MOCKUP_IMPLEMENTATION,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.RAW_TALENTS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      'GenAI Architect': [
        RECRUITING.ASSESSMENT.AI_ML_SPECIALTY,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.THINK_BIG,
        RECRUITING.ASSESSMENT.CODING,
        RECRUITING.ASSESSMENT.PEOPLE_MANAGEMENT,
      ],
      SDET: [
        RECRUITING.ASSESSMENT.AUTOMATION_CODING,
        RECRUITING.ASSESSMENT.TEST_TOOLS_FAMILIARITY,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.PROJECT_MANAGEMENT,
        RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE_N_SKILLS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
      Automation: [
        RECRUITING.ASSESSMENT.AUTOMATION_CODING,
        RECRUITING.ASSESSMENT.TEST_TOOLS_FAMILIARITY,
        RECRUITING.ASSESSMENT.PROBLEM_SOLVING,
        RECRUITING.ASSESSMENT.COMMUNICATION,
        RECRUITING.ASSESSMENT.PROJECT_MANAGEMENT,
        RECRUITING.ASSESSMENT.DOMAIN_KNOWLEDGE_N_SKILLS,
        RECRUITING.ASSESSMENT.MISSION_MENTALITY,
      ],
    },
    INTERNAL_REFERRAL: 'Internal Referral',
    CANDIDATE_SOURCE: {
      AICS_WEBSITE: 'AICS Website',
      BANK_104: '104',
      CAKE_RESUME: 'CakeResume',
      LINKED_IN: 'LinkedIn',
      HUNTER: 'Hunter',
      HR: 'HR',
      INTERNAL_REFERRAL: 'Internal Referral',
      TAIYI: 'Taiyi',
    },
    CANDIDATE_TABLE_SCHEMA: {
      LABEL: [
        RECRUITING.TABLE_SCHEMA.NAME,
        RECRUITING.TABLE_SCHEMA.CHINESE_NAME,
        RECRUITING.TABLE_SCHEMA.LOCATION,
        RECRUITING.TABLE_SCHEMA.SOURCE,
        RECRUITING.TABLE_SCHEMA.EDUCATION,
        RECRUITING.TABLE_SCHEMA.APPLIED_POSITION,
        RECRUITING.TABLE_SCHEMA.STATUS,
        RECRUITING.TABLE_SCHEMA.TS_SCORE,
        RECRUITING.TABLE_SCHEMA.NOTE,
        RECRUITING.TABLE_SCHEMA.PROCESS_DATE,
      ],
      FIELD: [
        RECRUITING.FIELD.NAME,
        RECRUITING.FIELD.CNAME,
        RECRUITING.FIELD.LOCATION,
        RECRUITING.FIELD.SOURCE,
        RECRUITING.FIELD.DEGREE,
        RECRUITING.FIELD.POSITION,
        RECRUITING.FIELD.PROCESS,
        RECRUITING.FIELD.SCORE,
        RECRUITING.FIELD.NOTE,
        RECRUITING.FIELD.PROCESS_DATE,
      ],
      WIDTH: [
        '8.00%',
        '8.00%',
        '8.00%',
        '8.50%',
        '9.00%',
        '8.50%',
        '6.50%',
        '6.50%',
        '25.50%',
        '12.00%',
      ],
      TH_CLASS: [
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_CENTER,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
      ],
      TD_CLASS: ['', '', '', '', '', '', '', RECRUITING.STYLE.TEXT_CENTER, '', ''],
    },
    TABLE_INDEX: {
      REPORT_TABLE: 0,
      POST_LOOP_TABLE: 1,
      POST_HIRE_FOLLOWUP: 2,
      POST_HIRE_SUMMARY: 3,
      POST_HIRE_OFFER_ACCEPTED: 4,
    },
    REPORT_TABLE_SCHEMA: {
      LABEL: [
        RECRUITING.TABLE_SCHEMA.STATUS,
        RECRUITING.TABLE_SCHEMA.NAME,
        RECRUITING.TABLE_SCHEMA.CHINESE_NAME,
        RECRUITING.TABLE_SCHEMA.EDUCATION,
        RECRUITING.TABLE_SCHEMA.APPLIED_POSITION,
        RECRUITING.TABLE_SCHEMA.RESUME,
        RECRUITING.TABLE_SCHEMA.INTERVIEW_TOPIC,
        RECRUITING.TABLE_SCHEMA.INTERVIEW_TIME,
        RECRUITING.TABLE_SCHEMA.REPORT,
      ],
      FIELD: [
        RECRUITING.FIELD.PROCESS_STATUS,
        RECRUITING.FIELD.NAME,
        RECRUITING.FIELD.CNAME,
        RECRUITING.FIELD.DEGREE,
        RECRUITING.FIELD.APPLIED_POSITION,
        RECRUITING.FIELD.ATTACHMENT,
        RECRUITING.FIELD.TOPIC,
        RECRUITING.FIELD.INTERVIEW_TIME,
        RECRUITING.FIELD.ACTION,
      ],
      WIDTH: [
        '7.50%',
        '12.00%',
        '10.00%',
        '9.00%',
        '11.50%',
        '8.00%',
        '15.00%',
        '15.00%',
        '12.00%',
      ],
      TH_CLASS: [
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_CENTER,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_CENTER,
      ],
      TD_CLASS: [
        '',
        '',
        '',
        '',
        '',
        RECRUITING.STYLE.TEXT_CENTER,
        '',
        '',
        RECRUITING.STYLE.TEXT_CENTER,
      ],
    },
    POST_LOOP_TABLE_SCHEMA: {
      LABEL: [
        RECRUITING.TABLE_SCHEMA.NAME,
        RECRUITING.TABLE_SCHEMA.CHINESE_NAME,
        RECRUITING.TABLE_SCHEMA.EDUCATION,
        RECRUITING.TABLE_SCHEMA.APPLIED_POSITION,
        RECRUITING.TABLE_SCHEMA.RESUME,
        RECRUITING.TABLE_SCHEMA.INTERVIEW_TIME,
        RECRUITING.TABLE_SCHEMA.REPORT,
      ],
      FIELD: [
        RECRUITING.FIELD.NAME,
        RECRUITING.FIELD.CNAME,
        RECRUITING.FIELD.DEGREE,
        RECRUITING.FIELD.APPLIED_POSITION,
        RECRUITING.FIELD.ATTACHMENT,
        RECRUITING.FIELD.INTERVIEW_TIME,
        RECRUITING.FIELD.ACTION,
      ],
      WIDTH: ['18.00%', '10.00%', '13.00%', '13.00%', '12.00%', '22.00%', '12.00%'],
      TH_CLASS: [
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_CENTER,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_CENTER,
      ],
      TD_CLASS: ['', '', '', '', RECRUITING.STYLE.TEXT_CENTER, '', RECRUITING.STYLE.TEXT_CENTER],
    },
    POST_HIRE_FOLLOWUP_TABLE_SCHEMA: {
      LABEL: [
        RECRUITING.TABLE_SCHEMA.NAME,
        RECRUITING.TABLE_SCHEMA.EDUCATION,
        RECRUITING.TABLE_SCHEMA.APPLIED_POSITION,
        RECRUITING.TABLE_SCHEMA.HIRING_DATE,
        RECRUITING.TABLE_SCHEMA.OFFER_DELIVERED_DATE,
        RECRUITING.TABLE_SCHEMA.OFFICE_TOUR_DATE,
        RECRUITING.TABLE_SCHEMA.ONBOARD_DATE,
        RECRUITING.TABLE_SCHEMA.TRACKING_REPORT,
      ],
      FIELD: [
        RECRUITING.FIELD.FOLLOWUP_NAME,
        RECRUITING.FIELD.EDUCATION,
        RECRUITING.FIELD.POSITION,
        RECRUITING.FIELD.HIRING_DATE,
        RECRUITING.FIELD.OFFER_DELIVERED_DATE,
        RECRUITING.FIELD.OFFICE_TOUR_DATE,
        RECRUITING.FIELD.ONBOARD_DATE,
        RECRUITING.FIELD.STATUS,
      ],
      WIDTH: ['13%', '12%', '12%', '12%', '12%', '12%', '12%', '10%'],
      TH_CLASS: [
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_LEFT,
        RECRUITING.STYLE.TEXT_CENTER,
      ],
      TD_CLASS: ['', '', '', '', '', '', '', RECRUITING.STYLE.TEXT_CENTER],
    },
    POST_HIRE_SUMMARY_TABLE_SCHEMA: {
      LABEL: [
        RECRUITING.TABLE_SCHEMA.NAME,
        RECRUITING.TABLE_SCHEMA.REPORTING_EM,
        RECRUITING.TABLE_SCHEMA.APPLIED_POSITION,
        RECRUITING.TABLE_SCHEMA.OFFER_DELIVERED_DATE,
        RECRUITING.TABLE_SCHEMA.OFFICE_TOUR_DATE,
        RECRUITING.TABLE_SCHEMA.LATEST_ACTION,
        RECRUITING.TABLE_SCHEMA.ACTION_DATE,
        RECRUITING.TABLE_SCHEMA.NOTE,
        RECRUITING.TABLE_SCHEMA.SOURCE,
        RECRUITING.TABLE_SCHEMA.OPTIONS,
      ],
      FIELD: [
        RECRUITING.FIELD.SUMMARY_NAME,
        RECRUITING.FIELD.MANAGER,
        RECRUITING.FIELD.POSITION,
        RECRUITING.FIELD.OFFER_DELIVERED_DATE,
        RECRUITING.FIELD.OFFICE_TOUR_DATE,
        RECRUITING.FIELD.STAGE,
        RECRUITING.FIELD.EVENT_DATE,
        RECRUITING.FIELD.NOTE,
        RECRUITING.FIELD.SOURCE,
        RECRUITING.FIELD.OPTIONS,
      ],
      WIDTH: ['12%', '10%', '10%', '10%', '8%', '10%', '8%', '', '1%', '1%'],
      TH_CLASS: [
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_CENTER,
        TEXT_LEFT,
        TEXT_CENTER,
      ],
      TD_CLASS: ['', '', '', '', '', '', '', '', '', ''],
    },
    POST_HIRE_OFFER_ACCEPTED_TABLE_SCHEMA: {
      LABEL: [
        RECRUITING.TABLE_SCHEMA.NAME,
        RECRUITING.TABLE_SCHEMA.REPORTING_EM,
        RECRUITING.TABLE_SCHEMA.APPLIED_POSITION,
        RECRUITING.TABLE_SCHEMA.ONBOARD_DATE,
        RECRUITING.TABLE_SCHEMA.OFFICE_TOUR_DATE,
        RECRUITING.TABLE_SCHEMA.ACTION_DATE,
        RECRUITING.TABLE_SCHEMA.NOTE,
        RECRUITING.TABLE_SCHEMA.SOURCE,
        RECRUITING.TABLE_SCHEMA.OPTIONS,
      ],
      FIELD: [
        RECRUITING.FIELD.SUMMARY_NAME,
        RECRUITING.FIELD.MANAGER,
        RECRUITING.FIELD.POSITION,
        RECRUITING.FIELD.ONBOARD_DATE,
        RECRUITING.FIELD.OFFICE_TOUR_DATE,
        RECRUITING.FIELD.EVENT_DATE,
        RECRUITING.FIELD.NOTE,
        RECRUITING.FIELD.SOURCE,
        RECRUITING.FIELD.OPTIONS,
      ],
      WIDTH: ['13%', '10%', '10%', '10%', '10%', '10%', '', '10%', '1%'],
      TH_CLASS: [
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_LEFT,
        TEXT_CENTER,
        TEXT_LEFT,
        TEXT_CENTER,
      ],
      TD_CLASS: ['', '', '', '', '', '', '', '', ''],
    },
    MANAGER_FOLLOW_UP_EXPIRE_DAY: 36,
  },
  POST_HIRE_ACTION_SEQ: {
    [RECRUITING.POST_HIRE_ACTIONS.MANAGER_FOLLOW_UP]: 1,
    [RECRUITING.POST_HIRE_ACTIONS.PRE_ONBOARDING]: 2,
  },
  POST_HIRE_ACTIONS_ID: {
    DEFAULT: '2f29835f-4d2a-48c1-a729-6d06c7e65f42',
    HIRING_MANAGER_FOLLOW_UP: '2f29835f-4d2a-48c1-a729-6d06c7e65f42',
    PRE_ONBOARDING: 'd02b6de4-48a2-4022-ae60-3656215c38f1',
  },
  RECRUITING_STRING: {
    ROUTE: {
      CREATE_CANDIDATE_PROFILE: 'recruiting-create-profile',
      CANDIDATE_INFO: 'recruiting-candidate-info',
      CANDIDATE_INFO_FROM_POST_HIRE: 'recruiting-candidate-info-from-post-hire',
      WRITE_POST_HIRE_REPORT: 'recruiting-write-post-hire-report',
      POST_HIRE_LIST: 'recruiting-post-hire-list',
      POST_LOOP_LIST: 'recruiting-manage-post-loop',
      WRITE_POST_LOOP_REPORT: 'recruiting-write-post-loop',
      WRITE_INTERVIEW_REPORT: 'recruiting-write-report',
      INTERVIEW_LIST: 'recruiting-manage-report',
      CANDIDATE_LIST: 'recruiting-manage-candidate',
    },
    ROLES: {
      ADMIN: 'admin',
      S_ADMIN: 'super_admin',
      PH_VIEW_ADMIN: 'post_hire_view_admin',
      PH_MANAGER: 'post_hire_manager',
      PL_ORGANIZER: 'post_loop_organizer',
      INTERVIEWER: 'interviewer',
      READER: 'reader',
    },
    TOGGLE_REPORT_FORM_TEXT: {
      SHOW: '▼ SHOW',
      HIDE: '▲ HIDE',
    },
    ROOT_EMIT: {
      UPDATE_SCROLLBAR: 'recruitingUpdateScrollbar',
    },
    LOADING: {
      PROCESSING: 'Processing ...',
    },
    TOAST: {
      COPY_TO_CLIPBOARD: {
        TITLE: '[Success]',
        MESSAGE: 'Profile information has been copied to clipboard.',
      },
    },
    BUTTON: {
      ADD_INTERVIEWER: '+ Add interviewer',
    },
    POST_HIRE_ACTIONS: {
      MANAGER_FOLLOW_UP: RECRUITING.POST_HIRE_ACTIONS.MANAGER_FOLLOW_UP,
      PRE_ONBOARDING: RECRUITING.POST_HIRE_ACTIONS.PRE_ONBOARDING,
    },
    NA: 'None',
    DELAY: 'Delay',
    NEW_REPORT: 'new_report',
    POST_HIRE_STATUS: {
      START: 'Start',
      IN_PROCESS: 'In Process',
      END: 'End',
      CLOSE: 'Close',
    },
  },
  MEMBER_TITLE: {
    CONSULTANT: 'Consultant',
  },
  RECRUITING_FIELD: RECRUITING.FIELD,
  FULLREPORT_CACHEKEY: 'aics_recruiting_fullreport',
  SHORTREPORT_CACHEKEY: 'aics_recruiting_shortreport',
  ATTENDANCE_TABLE_FIELD: [
    {
      key: 'name',
      label: 'Name',
      sortable: false,
    },
    {
      key: 'shouldWorkDay',
      label: '有效工作日',
      sortable: true,
    },
    {
      key: 'avgWorkHour',
      label: '平均工時',
      sortable: true,
    },
    {
      key: 'avgOnStart',
      label: '平均上班時間',
      sortable: true,
    },
    {
      key: 'avgOnEnd',
      label: '平均下班時間',
      sortable: true,
    },
    {
      key: 'abnormalCount',
      label: '不正常出勤天數',
      sortable: true,
    },
    {
      key: 'abnormalRation',
      label: '不正常出勤比例',
      sortable: true,
    },
    {
      key: 'supplementCount',
      label: '累積補登次數',
      sortable: true,
    },
  ],
  ATTENDANCE_TABLE_DASHBOARD_FIELD: [
    {
      key: 'shouldWorkDay',
      label: '有效工作日',
      sortable: false,
    },
    {
      key: 'avgWorkHour',
      label: '平均工時',
      sortable: false,
    },
    {
      key: 'avgOnStart',
      label: '平均上班時間',
      sortable: false,
    },
    {
      key: 'avgOnEnd',
      label: '平均下班時間',
      sortable: false,
    },
    {
      key: 'abnormalCount',
      label: '不正常出勤天數',
      sortable: false,
    },
    {
      key: 'abnormalRation',
      label: '不正常出勤比例',
      sortable: false,
    },
    {
      key: 'supplementCount',
      label: '累積補登次數',
      sortable: true,
    },
  ],
  ATTENDANCE_EXPORT_TYPE: {
    TEAM: 'summary',
    PERSON: 'person',
  },
  INTERVIEW_PROCESS: {
    FIRST_TS: '1st TS',
    SECOND_TS: '2nd TS',
    THIRD_TS: '3rd TS',
    FULL_LOOP: 'Full Loop',
    POST_LOOP: 'Post Loop',
    OFFER_ACCEPTED: 'Offer Accepted',
    HR_INTERVIEW: 'HR Interview',
    HIRE: 'Hire',
  },
  // Interview Process Date Field Name
  IP_DATE_FIELD: {
    DATE: 'Date',
    ONBOARDING_DATE: 'Onboarding',
    INTERVIEW_CONFIRMED: 'Confirmed',
    INTERVIEW_DATE: 'Interview',
    NA: '',
  },
  AAA_CONST: {
    WEEK_EPOCH: '2021-12-13 00:00:00',
    DEFAULT: {
      reportItems: [
        {
          key: 'summary',
          title: 'Give a brief summary of your accomplishments this week',
        },
        {
          key: 'skill',
          title: 'What skills (both technical and non-technical) did you gain this week?',
        },
        {
          key: 'challenge',
          title:
            'What are the challenges you faced during the past week, if any? How did you overcome them?',
        },
        {
          key: 'difficulty',
          title: "For me, the difficulty level of this week's assignment was:",
          type: 'radio',
          value: ['Low', 'Medium', 'High'],
        },
      ],

      feedbackItems: [
        {
          key: 'strength',
          title: 'In which areas did the trainee do well?',
        },
        {
          key: 'weakness',
          title: 'In which areas can the trainee improve further, and how?',
        },
        {
          key: 'overall',
          title: "Overall, the trainee's performance in this assignment was:",
          type: 'radio',
          value: ['Poor', 'Average', 'Good'],
        },
      ],

      evaluationItems: [
        {
          key: 'coding',
          title: 'Coding',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        {
          key: 'probsolv',
          title: 'Problem solving',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        {
          key: 'comm',
          title: 'Team fit/Comm',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        {
          key: 'talent',
          title: 'Raw talents',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        // 4 dimensions for designer evaluation
        {
          key: 'understand',
          title: 'Understanding',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        {
          key: 'insight',
          title: 'Insight',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        {
          key: 'exec',
          title: 'Execution',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        {
          key: 'comm2',
          title: 'Communication',
          type: 'dropdown',
          value: ['Good', 'Average', 'Poor'],
        },
        // end of the 4 dimensions for designer evaluation
        {
          key: 'strength',
          title: 'Strength',
        },
        {
          key: 'weakness',
          title: 'Weakness',
        },
        {
          key: 'score',
          title: 'Score',
          type: 'dropdown',
          value: [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0.5, 0],
        },
        {
          key: 'decision',
          title: 'Recommendation',
          type: 'dropdown',
          value: [
            'Confirm',
            'Lean to confirm',
            'Extend probation',
            'Lean to not confirm',
            'Do not confirm',
          ],
        },
      ],
    },
  },
  EXTERNAL_LINKS: {
    BOOTCAMP_CURRICULUM: 'https://aicswiki.azurewebsites.net/training/bootcamp',
    POST_HIRE_GUIDE:
      'https://asus.sharepoint.com/sites/EA/_layouts/15/Doc.aspx?sourcedoc=%7B416cfdb1-5941-45c8-a24b-1b78a019dc95%7D&action=view&wd=target%28Recruiting%20SOP.one%7C4c55dac0-05ad-475e-8924-7d89696cc412%2FPost-Hire%20Process%7C7e1de63e-4f6f-8745-8ea2-59bf971d127e%2F%29&wdorigin=703&wdpreservelink=1',
  },
};

module.exports = config;
