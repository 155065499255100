<template>
  <div id="app" :class="classObj">
    <router-view />
  </div>
</template>
<script>
import HttpStatus from 'http-status-codes';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { COOKIE } from '@/utils/constant';

export default {
  name: 'App',
  computed: {
    ...mapState('auth', ['login', 'memberId']),
    ...mapGetters('member', ['member']),
    classObj() {
      return {
        bg: !this.$store.state.auth.login,
        siraya: this.$route.path === '/login/callback' || this.$route.path === '/ad/login/callback',
      };
    },
  },
  watch: {
    login(val) {
      if (!val) {
        this.$cookie.delete(COOKIE.TOKEN);
        if (this.$route.path !== '/') {
          this.$router.push('/').catch(err => {});
        }
      } else {
        this.fetchMembers().then(() => {
          this.setMyself(this.member(this.memberId));
        });
      }
    },
  },
  methods: {
    ...mapActions('member', ['fetchMembers']),
    ...mapMutations('member', ['setMyself']),
    setupAuthInterceptors() {
      this.axios.interceptors.response.use(
        res => res,
        error => {
          if (error.response && error.response.status === HttpStatus.UNAUTHORIZED) {
            this.$store.commit('auth/logout');
          }

          return Promise.reject(error);
        }
      );
    },
  },
  created() {
    this.setupAuthInterceptors();
    if (this.login) {
      this.fetchMembers();
    }
  },
};
</script>
<style scoped>
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.bg {
  background-color: #f3f3f3;
}

.siraya {
  background-image: url('/img/siraya.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>

<style lang="scss">
#vueAlert .btn-confirm {
  cursor: pointer;
}
#vueConfirm .confirm-dialog {
  box-shadow: 0 0 0 1px #b5bec9;
}
#vueConfirm .btn-confirm .flex-1 {
  cursor: pointer;
}
</style>
