import moment from 'moment-timezone';
import { OKR_START_DATE, OKR_BUFFER_DAYS } from '@/utils/constant';
moment.tz.setDefault('Asia/Taipei');

const timeFormat = 'YYYY-MM-DD';
const yearFormat = 'YYYY';
const newQuarterDefStartAt = '2020/11/30';

export default {
  methods: {
    getYear() {
      return `${moment().format(yearFormat)}`;
    },
    getNextYear() {
      return `${moment()
        .add(1, 'years')
        .format(yearFormat)}`;
    },
    getLastYear() {
      return `${moment()
        .subtract(1, 'years')
        .format(yearFormat)}`;
    },
    getAicsLastQuarterYear() {
      if (moment(new Date(), timeFormat).isAfter(moment(newQuarterDefStartAt, timeFormat))) {
        if (moment().month() === 0 || moment().month() === 1) {
          return this.getLastYear();
        }
      } else {
        if (moment().month() === 0 || moment().month() === 1 || moment().month() === 2) {
          return this.getLastYear();
        }
      }
      return this.getYear();
    },
    getAicsCurrentYear() {
      if (
        moment(new Date(), timeFormat).isAfter(moment(newQuarterDefStartAt, timeFormat)) &&
        moment().month() === 11
      ) {
        return this.getNextYear();
      }
      return this.getYear();
    },
    getAicsNextQuarter() {
      return `Q${this.getAicsNextQuarterIndex()}`;
    },
    getAicsNextQuarterIndex() {
      const currentQuarter = this.getAicsCurrentQuarter();
      return currentQuarter === 4 ? 1 : currentQuarter + 1;
    },
    getAicsLastQuarterIndex() {
      const currentQuarter = this.getAicsCurrentQuarter();
      return currentQuarter === 1 ? 4 : currentQuarter - 1;
    },
    getAicsCurrentQuarter() {
      if (moment(new Date(), timeFormat).isAfter(moment(newQuarterDefStartAt, timeFormat))) {
        const quarterMonth = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const sequenceOfYear = quarterMonth.findIndex(value => {
          return moment().month() === value;
        });
        // quarter will be 1, 2, 3 or 4
        return Math.ceil((sequenceOfYear + 1) / 3);
      } else {
        return moment().quarter();
      }
    },
    getAicsOffsetQuarter(days) {
      if (moment(this.yearSelect, 'YYYY').isAfter(moment('2020', 'YYYY'))) {
        const quarterMonth = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const sequenceOfYear = quarterMonth.findIndex(value => {
          return (
            moment()
              .subtract(days, 'days')
              .month() === value
          );
        });
        // quarter will be 1, 2, 3 or 4
        return Math.ceil((sequenceOfYear + 1) / 3);
      } else {
        return moment()
          .subtract(days, 'days')
          .quarter();
      }
    },
    showAicsNewQuarter() {
      const currentQuarter = this.getAicsCurrentQuarter();
      const quarterMonth = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      const endOfQuarterIndex = currentQuarter * 3 - 1;
      return (
        moment(quarterMonth[endOfQuarterIndex] + 1, 'M')
          .endOf('month')
          .format('YYYY-MM-[01]') <= `${moment().format(timeFormat)}` &&
        this.optionsYear[0] === this.selectYear
      );
    },
    isLastQuarterMonth() {
      const currentQuarter = this.getAicsCurrentQuarter();
      const endOfQuarterIndex = currentQuarter * 3 - 1;
      const quarterMonth = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      return (
        moment(quarterMonth[endOfQuarterIndex] + 1, 'M')
          .endOf('month')
          .format('YYYY-MM-[01]') <= `${moment().format(timeFormat)}`
      );
    },
    fetchYear() {
      this.optionsYear = [];
      // fetch data to year
      for (let index = moment().format(yearFormat); index >= OKR_START_DATE.YEAR; index--) {
        this.optionsYear.push(`${index}`);
      }
      // After YYYY-11-01, add next year option
      if (moment() >= moment(`${moment().format(yearFormat)}-11-01`)) {
        this.optionsYear.unshift(
          `${moment()
            .add(1, 'years')
            .format(yearFormat)}`
        );
      }
    },
    fetchQuarter() {
      if (moment() >= moment(`${this.selectYear}-08-01`)) {
        this.optionsQuarter = ['Q1', 'Q2', 'Q3', 'Q4'];
      } else if (moment() >= moment(`${this.selectYear}-05-01`)) {
        this.optionsQuarter = ['Q1', 'Q2', 'Q3'];
      } else if (moment() >= moment(`${this.selectYear}-02-01`)) {
        this.optionsQuarter = ['Q1', 'Q2'];
      } else {
        this.optionsQuarter = ['Q1'];
      }

      // remove before start option
      if (this.selectYear === `${OKR_START_DATE.YEAR}` && OKR_START_DATE.QUARTER === 2) {
        this.optionsQuarter = this.optionsQuarter.filter(item => item !== 'Q1');
        this.selectQuarter = 'Q2';
      } else if (this.selectYear === `${OKR_START_DATE.YEAR}` && OKR_START_DATE.QUARTER === 3) {
        this.optionsQuarter = this.optionsQuarter.filter(item => item !== 'Q1' && item !== 'Q2');
        this.selectQuarter = 'Q3';
      } else if (this.selectYear === `${OKR_START_DATE.YEAR}` && OKR_START_DATE.QUARTER === 4) {
        this.optionsQuarter = this.optionsQuarter.filter(
          item => item !== 'Q1' && item !== 'Q2' && item !== 'Q3'
        );
        this.selectQuarter = 'Q4';
      } else if (this.selectYear === `${OKR_START_DATE.YEAR}` && OKR_START_DATE.QUARTER === 1) {
        this.selectQuarter = 'Q1';
      }
      this.optionsQuarter = this.optionsQuarter.reverse();
    },
    getMonthDisable() {
      const index =
        parseInt(this.yearSelect) > 2020 ? (moment().month() + 1) % 3 : moment().month() % 3;
      const date = moment().date();
      if (this.isNextYearQuarter()) {
        return [false, false, false];
      } else if (this.isLastYearQuarter()) {
        return [true, true, !(index === 0 && date <= OKR_BUFFER_DAYS)];
      }
      return [0, 1, 2].map(expected => {
        return !(index <= expected || (index === (expected + 1) % 3 && date <= OKR_BUFFER_DAYS));
      });
    },
    getNoteDate(data) {
      if (moment().diff(data, 'days') < 1) {
        return `${Math.round(moment().diff(data, 'hours'))} hours ago`;
      } else {
        return `${moment(data).format(timeFormat)}`;
      }
    },
    isEditableYearQuarter() {
      return this.isThisYearQuarter() || this.isOffsetYearQuarter() || this.isNextYearQuarter();
    },
    isThisYearQuarter() {
      let targetYear = `${moment().format(yearFormat)}`;
      if (moment().month() === 11) {
        targetYear = `${moment()
          .add(1, 'year')
          .format(yearFormat)}`;
      }
      return (
        this.yearSelect === targetYear && this.quarterSelect === `Q${this.getAicsCurrentQuarter()}`
      );
    },
    isOffsetYearQuarter() {
      return (
        this.yearSelect ===
          `${moment()
            .subtract(OKR_BUFFER_DAYS, 'days')
            .year()}` && this.quarterSelect === `Q${this.getAicsOffsetQuarter(OKR_BUFFER_DAYS)}`
      );
    },
    isLastYearQuarter() {
      // (this year and quarter) or (last quarter and 3 days)
      return (
        this.yearSelect ===
          (moment().month() === 0 || moment().month() === 1
            ? this.getLastYear()
            : this.getYear()) &&
        this.quarterSelect ===
          (this.getAicsCurrentQuarter() === 1 ? 'Q4' : `Q${this.getAicsCurrentQuarter() - 1}`)
      );
    },
    isNextYearQuarter() {
      let targetYear = this.getYear();
      const targetQuarter = this.getAicsNextQuarterIndex();
      if (targetQuarter === 1) {
        targetYear = this.getNextYear();
      }
      return this.yearSelect === targetYear && this.quarterSelect === `Q${targetQuarter}`;
    },
  },
};
