import jwt from 'jsonwebtoken';
import { PRIVILEGE } from '@/utils/constant';

const auth = {
  namespaced: true,
  state: {
    login: false,
    token: null,
    name: null,
    email: null,
    userId: null,
    privilege: null,
    session: null,
    memberId: null,
    picNew: false,
  },
  mutations: {
    login(state, token) {
      const decode = jwt.decode(token);
      state.login = true;
      state.token = token;
      state.name = decode.name;
      state.email = decode.email;
      state.userId = decode.sub;
      state.privilege = decode.privilege;
      state.session = decode.session;
      state.memberId = decode.member_id;
    },
    logout(state) {
      state.login = false;
      state.token = null;
      state.name = null;
      state.email = null;
      state.userId = null;
      state.privilege = null;
      state.session = null;
      state.memberId = null;
    },
    setPicNew(state, picNew) {
      state.picNew = picNew;
    },
  },
  getters: {
    loginState: state => state.login,
    userName: state => state.name,
    session: state => state.session,
    canEdit: state => !!(state.privilege && state.privilege.includes(PRIVILEGE.ADMIN)),
    canEditShift: state => !!(state.privilege && state.privilege.includes(PRIVILEGE.SCHEDULER)),
    memberId: state => state.memberId,
    guest: state => !!(state.privilege && state.privilege.includes(PRIVILEGE.GUEST)),
    nobility: state => !!(state.privilege && state.privilege.includes(PRIVILEGE.NOBILITY)),
    picNew: state => state.picNew,
    attendanceAdmin: state =>
      !!(state.privilege && state.privilege.includes(PRIVILEGE.ATTENDANCE_ADMIN)),
  },
};

export default auth;
