export default {
  programTopics: {
    ['intro']: 'Introduction',
    ['coding&security']: 'Coding & Security Principles',
    ['systemdocker']: 'System (dockeer, K8s)',
    ['systemAzure']: 'System (Azure & Security Principles)',
    ['Devops']: 'DevOps & CI/CD',

    ['JsTs']: 'JavaScript & TypeScript',
    ['Framework']: 'Redis, feathers.js framework',
    ['Flex']: 'FlexSearch',
    ['State']: 'Xstate + state-engine, state-service, opd-frontend, fe-engine, patient service',
    ['YJS']: 'YJS',
    ['TraceMarathon']: 'Backend Code Trace Marathon',

    ['widget']: 'xHIS Widget Development',

    ['Bash']: 'Bash scripting',
    ['Airflow']: 'Airflow',
    ['Jupyter']: 'Jupyter Notebook',

    ['Db']: 'MongoDB, SQL',
    ['notebooks']: 'Jupyter Notebook, Pandas',
    ['airflowDags']: 'Airflow (Develop DAGs, Performance Tuning)',
    ['storage']: 'Storage Service (API, Write back to Sybase)',

    ['RuleSys']: 'Rule System Development',
    ['FormBuilder']: 'Form Builder Development',

    ['S1']: 'S1 - Vue (Basic)',
    ['S2']: 'S2 - Vue (Advanced)',
    ['S3']: 'S3 - StateEngine',
    ['S4']: 'S4 - Typescript',
    ['S5']: 'S5 - Mongo',
    ['S6']: 'S6 - PyTest',
    ['S7']: 'S7 - JavaScript',

    ['PMS']: 'Product & Project Management Skills',
    ['DM']: 'Domain Knowledge',
    ['PS']: 'Presentation Skills',
    ['MKR']: 'Market Research',
    ['SS']: 'Soft Skills',

    ['UxBasic1']: 'Basic design skills 1',
    ['UxBasic2']: 'Basic design skills 2',
    ['UxImpl1']: 'Light implementation 1',
    ['UxImpl2']: 'Light implementation 2',
    ['UxAdv1']: 'Advanced topic 1',
    ['UxAdv2']: 'Advanced topic 2',
  },

  programCategory: [
    {
      desc: 'Bootcamp General(SDE)',
      content: ['intro', 'coding&security', 'systemdocker', 'systemAzure', 'Devops'],
    },
    {
      desc: 'Backend (SDE)',
      content: ['JsTs', 'Framework', 'Flex', 'State', 'YJS', 'TraceMarathon'],
    },
    {
      desc: 'Frontend (SDE)',
      content: ['widget'],
    },
    {
      desc: 'Tool (SDE)',
      content: ['Bash', 'Airflow', 'Jupyter'],
    },
    {
      desc: 'DaaS (SDE)',
      content: ['Db', 'notebooks', 'airflowDags', 'storage'],
    },
    {
      desc: 'Rule (SDE)',
      content: ['RuleSys', 'FormBuilder'],
    },
    {
      desc: 'Special Training',
      content: ['S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7'],
    },
    {
      desc: 'Bootcamp (PM)',
      content: ['PMS', 'DM', 'PS', 'MKR', 'SS'],
    },
    {
      desc: 'Bootcamp (Designer)',
      content: ['intro', 'UxBasic1', 'UxBasic2', 'UxImpl1', 'UxImpl2', 'UxAdv1', 'UxAdv2'],
    },
  ],

  defaultProgram: [
    { week: '1', topic: 'intro' },
    { week: '2', topic: 'intro' },
    { week: '3', topic: 'intro' },
    { week: '4', topic: 'intro' },
    { week: '5', topic: 'intro' },
    { week: '6', topic: 'intro' },
    { week: '7', topic: 'intro' },
    { week: '8', topic: 'intro' },
    { week: '9', topic: 'intro' },
    { week: '10', topic: 'intro' },
    { week: '11', topic: 'intro' },
    { week: '12', topic: 'intro' },
  ],
};
