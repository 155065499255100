import { API, RECRUITING, RECRUITING_FIELD } from '@/utils/constant';
import utilsRecruiting from '@/utils/recruiting';

/**
 * Since the datetime saved in SQL is UTC, we should convert it to local time
 * for displaying.
 * E.g., "2015-02-20T19:29:31.238Z" -> "2015/02/21 03:29:31"
 */
function leftPadZero(str, max) {
  str = str.toString();
  return str.length < max ? leftPadZero(`0${str}`, max) : str;
}
function UTC2Local(utc) {
  const datetime = new Date(utc);
  return `${datetime.getFullYear()}/${leftPadZero(datetime.getMonth() + 1, 2)}/${leftPadZero(
    datetime.getDate(),
    2
  )} ${leftPadZero(datetime.getHours(), 2)}:${leftPadZero(datetime.getMinutes(), 2)}:${leftPadZero(
    datetime.getSeconds(),
    2
  )}`;
}

/**
 * Convert number month and short month
 * E.g., "02" <-> "Feb"
 */
function numberToMonth(number_string) {
  return number_string != null ? RECRUITING.OPTIONS_MONTH[parseInt(number_string, 10) - 1] : null;
}
function monthToNumber(month_name) {
  return RECRUITING.OPTIONS_MONTH.includes(month_name)
    ? (RECRUITING.OPTIONS_MONTH.indexOf(month_name) + 1).toString()
    : null;
}

/**
 * Valid the start time is early than end time or not (HH:mm)
 * E.g., start_time = "13:50", end_time = "08:20";  <-- is_valid will be false
 */
function validTimeRange(start_time, end_time) {
  let is_valid = true;
  if (
    start_time != null &&
    end_time != null &&
    new Date(`1/1/1999 ${start_time}:00`) > new Date(`1/1/1999 ${end_time}:00`)
  ) {
    is_valid = false;
  }
  return is_valid;
}

/**
 * Download file from Azure blob storage. The download link is provided by
 * back-end and it concat with SAS.
 */
function downloadAttachment(attachment_id, attachment_name) {
  this.axios({
    method: 'get',
    url: `${API.RECRUITING.ATTACHMENT}/${attachment_id}`,
  }).then(async response => {
    const url = response.data;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment_name);
    link.click();
  });
}

/**
 * Upload file to backend which will be saved to Azure blob storage.
 * @param {String} candidateId the id of the candidate record
 * @param {String} filename the filename of the file to be uploaded
 * @param {Object} data the binary data to be uploaded
 */
function uploadAttachment(candidateId, filename, data) {
  const attachment = new FormData();
  attachment.append('files', data, filename);
  return this.axios
    .post(`${API.RECRUITING.ATTACHMENT}?candidate_id=${candidateId}`, attachment, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => true)
    .catch(error => {
      this.$bvToast.toast('Failed to save attachment', { title: '[Error]', variant: 'danger' });
    });
}

/**
 * Fetch the actual download link from the attachment id
 * @param {Object} attachment The attachment object
 * @returns {String} the download link
 */
async function getAttachmentUrl(attachment) {
  const response = await this.axios.get(`${API.RECRUITING.ATTACHMENT}/${attachment.id}`);
  return response.data;
}

/**
 * Parse the recording file name to human readable one
 * @param {String} filename The recording name
 * @returns {String} the parsed name for the recording file
 */
function parseRecordingName(filename) {
  // YYYYMMDDhhmmss.webm
  const name = filename.split('_')[1];
  const list = name && name.match(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})\.webm$/);
  return list && list.length === 7
    ? `${list[1]}-${list[2]}-${list[3]} ${list[4]}:${list[5]}`
    : filename;
}

/**
 * Check if this attachment is a recording file
 * @param {Object} attacment The attachment object
 * @returns {Boolean} true if the given attachment is a recording file, else false
 */
function isRecordingFile(attachment) {
  return attachment.name.endsWith('.webm');
}

/**
 * Copy HTML element to clipboard
 * https://stackoverflow.com/questions/55440037/copy-rich-text-to-clipboard-with-styles-from-css-classes
 */
function copyToClipboard(element) {
  const block_level_elements = [
    'P',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'OL',
    'UL',
    'DIV',
    'FORM',
    'HR',
    'TABLE',
  ]; // array off all block level elements
  const newelment = document.createElement('div'); // create new Element so we can vhange elments like we need
  newelment.innerHTML = document.getElementById(element).innerHTML; // copy target Element to the new Element
  newelment.style.opacity = 0; // hide new Element to body
  document.body.appendChild(newelment); // add new Element to body

  //-----------------childs--------------
  const descendents = newelment.getElementsByTagName('*'); // get all element childs
  for (let i = 0; i < descendents.length; ++i) {
    // loop in childs
    const style = window.getComputedStyle(descendents[i]); // get defult Style
    const dis = style.getPropertyValue('display');
    const tagname = descendents[i].tagName; // get defult tag name
    if (dis.includes('inline') && block_level_elements.includes(tagname)) {
      // true : Element is a block level elements and css display is inline
      const defultcss = document.defaultView.getComputedStyle(descendents[i], '').cssText; // get all Element style include default style
      descendents[i].outerHTML = descendents[i].outerHTML.replace(
        new RegExp(tagname, 'ig'),
        'span'
      ); // chang Element tag from block level elements to inline level elements (span)  // todo: need to change RegExp to tag name only not inner text
      descendents[i].style.cssText = defultcss; // add all Element style include default style to new tag
    }
  }

  //-----------------copy new Element--------------
  const doc = document;
  let range, selection;
  if (doc.body.createTextRange) {
    range = doc.body.createTextRange();
    range.moveToElementText(newelment);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = doc.createRange();
    range.selectNodeContents(newelment);
    selection.removeAllRanges();
    selection.addRange(range);
  }
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
  document.body.removeChild(newelment); // remove new Element from document
}

function associateCandidateSearch(state) {
  // collect search parameters
  // page
  let para = `?$limit=${RECRUITING.PER_PAGE}&$skip=${(state.candidates_page - 1) *
    RECRUITING.PER_PAGE}`;

  // sort
  if (state.candidates_sort) {
    const col =
      state.candidates_sort.field === RECRUITING_FIELD.PROCESS_DATE
        ? RECRUITING_FIELD.PROCESS_START_AT
        : state.candidates_sort.field;
    const asc = state.candidates_sort.type === 'asc' ? 'true' : 'false';
    para = `${para}&sortby=${col}&asc=${asc}`;
  }

  // search
  if (state.candidates_search.name) {
    para = `${para}&name=${state.candidates_search.name}`;
  }
  for (const i in state.candidates_search.location) {
    para = `${para}&location[${i}]=${state.candidates_search.location[i]}`;
  }
  for (const i in state.candidates_search.education) {
    para = `${para}&education[${i}]=${state.candidates_search.education[i]}`;
  }
  for (const i in state.candidates_search.position) {
    para = `${para}&position[${i}]=${state.candidates_search.position[i]}`;
  }
  for (const i in state.candidates_search.status) {
    para = `${para}&status[${i}]=${state.candidates_search.status[i]}`;
  }
  for (const i in state.candidates_search.source) {
    para = `${para}&source[${i}]=${state.candidates_search.source[i]}`;
  }
  const timezone_min = new Date().getTimezoneOffset();
  if (state.candidates_search.date_from) {
    const ymd = state.candidates_search.date_from.split('-');
    para = `${para}&process_from=${utilsRecruiting.UTC2Local(
      new Date(ymd[0], ymd[1] - 1, ymd[2], 0, timezone_min, 0)
    )}`;
  }
  if (state.candidates_search.date_to) {
    const ymd = state.candidates_search.date_to.split('-');
    para = `${para}&process_to=${utilsRecruiting.UTC2Local(
      new Date(ymd[0], ymd[1] - 1, ymd[2], 23, 59 + timezone_min, 59)
    )}`;
  }
  return para;
}
export default {
  UTC2Local,
  numberToMonth,
  monthToNumber,
  validTimeRange,
  downloadAttachment,
  uploadAttachment,
  getAttachmentUrl,
  parseRecordingName,
  isRecordingFile,
  copyToClipboard,
  associateCandidateSearch,
};
