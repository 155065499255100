import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookie from 'vue-cookie';
import { COOKIE, HEADER } from '@/utils/constant';

Vue.use(VueAxios, axios);

axios.interceptors.request.use(
  config => {
    const reqConfig = config;
    if (!config.headers[HEADER.AUTH]) {
      reqConfig.headers[HEADER.AUTH] = VueCookie.get(COOKIE.TOKEN);
    }
    return reqConfig;
  },
  error => Promise.reject(error)
);
