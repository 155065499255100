import auth from '@/store/auth';
import member from '@/store/member';
import peerReview from '@/store/peerReview';
import project from '@/store/project';
import okr from '@/store/okr';
import shift from '@/store/shift';
import recruiting from '@/store/recruiting';
import attendance from '@/store/attendance';
import pic from '@/store/pic';
import aaaBootcamp from '@/store/aaaBootcamp.js';

export default {
  modules: {
    auth,
    member,
    peerReview,
    project,
    okr,
    shift,
    recruiting,
    attendance,
    pic,
    aaaBootcamp,
  },
};
